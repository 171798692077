import { useMutation, useQuery } from "react-query";
import axiosWrapper from "service/jwtService/jwtService";

const getData = async (params) => {

    if (!params) return

    return await axiosWrapper({
        url: `/users/findById?uid=${params}`,
        method: 'get'
    })

}

const useGetUserById = (params) => {
    const query = useQuery([`/users/findById?uid=${params}`, params], () => getData(params), {
        keepPreviousData: false,
        retry: true,
        refetchOnWindowFocus: false,
        onSuccess: () => { },
        onError: () => { },
    })

    const { data: { data = {} } = {}, isLoading, isFetching, refetch: fetch } = query

    return { data, isLoading, isFetching, fetch }
}

export default useGetUserById;
