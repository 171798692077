import React, { useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Filters from "../userMgt/components/Filters";
import MainTable from "../shareComponent/table/MainTable";
import { columnsRegisterUserData } from "./helper";
import { SocketAdminProvider } from "fuse/layout-admin/layout-master";
import { useFilters } from "share/function";
import useGetUserData from "../userMgt/hook/useGetUserData";
import _ from "lodash";
import { dialogConfirmId } from "./constant";
import DialogConfirm from "../shareComponent/dialog/DialogConfirm";
import { useDispatch } from "react-redux";
import {
  closePopup,
  openPopup,
} from "redux/action/custom-popup/customPopupAction";
import { useUpdateUser } from "../userMgt/hook/useUpdateUser";
import toast from "react-hot-toast";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";

const RegisterUser = () => {
  const dispatch = useDispatch();

  const [showSlice, onShowSlice] = useState({
    initData: null,
    isEdit: true,
  });
  const { socket } = useContext(SocketAdminProvider);

  const defaultValues = {
    userName: "",
  };

  const method = useForm({
    mode: "onSubmit",
    defaultValues,
  });

  const { reset } = method;

  const { count, setCount, filters, onFilter } = useFilters(defaultValues);

  const {
    data: dataUserAPI,
    isLoading,
    fetch,
    isFetching,
  } = useGetUserData(filters);

  const {mutate: updateUser, isLoading: isLoadingUpdateUser} = useUpdateUser();

  const dataUser = useMemo(() => {
    if (!_.isEmpty(dataUserAPI)) {
      return dataUserAPI?.filter((user) => user.status === "created");
    }
  }, [dataUserAPI]);

  const handleSearch = (data) => {
    onFilter({ userName: data.userName });
  };

  const handleRefresh = async () => {
    reset();
    onFilter({});
    await fetch();
  };

  const handleActived = (data, activated) => {
    onShowSlice({ initData: data });
    dispatch(
      openPopup({
        id: activated
          ? dialogConfirmId.activatedUser
          : dialogConfirmId.rejectUser,
      })
    );
  };

  const confirmActivatedUser = async () => {
    const { uid } = showSlice.initData;
    await updateUser({
      Data: {
        uid,
        status: "activated",
      },
      onSuccess: async (res) => {
        dispatch(closePopup({ id: dialogConfirmId.activatedUser }));
        toast.success("Activated successful");
        await fetch();
        onShowSlice({ initData: null, isEdit: true });
      },
      onError: (err) => {
        toast.error("Activated error");
      },
    });
  };

  const confirmRejectUser = async () => {
    const { uid } = showSlice.initData;
    await updateUser({
      Data: {
        uid,
        status: "deleted",
      },
      onSuccess: async (res) => {
        dispatch(closePopup({ id: dialogConfirmId.rejectUser }));
        toast.success("Reject successful");
        await fetch();
        onShowSlice({ initData: null, isEdit: true });
      },
      onError: (err) => {
        toast.error("Reject error");
      },
    });
  };

  useLoadingEffect(isLoading || isLoadingUpdateUser);

  return (
    <div className="h-full w-full">
      <FormProvider {...method}>
        <Filters onSubmit={handleSearch} onRefresh={handleRefresh} />
      </FormProvider>
      <div className="h-[calc(100%_-_80px)] w-full p-[10px_0px]">
        <MainTable
          columns={columnsRegisterUserData(handleActived)}
          data={dataUser}
          rowSelect={true}
          rowKey="uid"
          pageSize={20}
        />
      </div>

      <DialogConfirm
        id={dialogConfirmId.activatedUser}
        render={
          <div className="flex flex-col justify-between p-[10px_20px] 2xl:min-w-[200px] 2xl:min-h-[100px]">
            <div className="flex justify-between dialog-header">
              <h5 className="lg:text-[20px] 2xl:text-[23px]">Confirm</h5>
              {/* <button className="outline-none border-none bg-transparent">&times;</button> */}
            </div>
            <div className="dialog-body my-[30px]">
              <p className="lg:text-[13px] 2xl:text-[17px]">
                Are you sure to activated user?
              </p>
            </div>
            <div className="dialog-footer flex items-center justify-end">
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#C83737] text-[#fff] flex items-center justify-center"
                onClick={() => {
                  onShowSlice({ initData: null, isEdit: true });
                  dispatch(closePopup({ id: dialogConfirmId.activatedUser }));
                }}
              >
                Cancel
              </button>
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] flex items-center justify-center ml-[10px]"
                onClick={confirmActivatedUser}
              >
                OK
              </button>
            </div>
          </div>
        }
      />

      <DialogConfirm
        id={dialogConfirmId.rejectUser}
        render={
          <div className="flex flex-col justify-between p-[10px_20px] 2xl:min-w-[200px] 2xl:min-h-[100px]">
            <div className="flex justify-between dialog-header">
              <h5 className="lg:text-[20px] 2xl:text-[23px]">Confirm</h5>
              {/* <button className="outline-none border-none bg-transparent">&times;</button> */}
            </div>
            <div className="dialog-body my-[30px]">
              <p className="lg:text-[13px] 2xl:text-[17px]">
                Are you sure to reject user?
              </p>
            </div>
            <div className="dialog-footer flex items-center justify-end">
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#C83737] text-[#fff] flex items-center justify-center"
                onClick={() => {
                  onShowSlice({ initData: null, isEdit: true });
                  dispatch(closePopup({ id: dialogConfirmId.rejectUser }));
                }}
              >
                Cancel
              </button>
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] flex items-center justify-center ml-[10px]"
                onClick={confirmRejectUser}
              >
                OK
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default RegisterUser;
