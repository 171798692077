import { useMutation, useQuery } from "react-query";
import axiosWrapper from "service/jwtService/jwtService";

const getData = async (params) => {

    return await axiosWrapper({
        url: `/users/findAll`,
        method: 'post',
        data: params
    })

}

const useGetUserData = (params) => {
    const query = useQuery([`/users/findAll`, params], () => getData(params), {
        keepPreviousData: false,
        retry: true,
        refetchOnWindowFocus: false,
        onSuccess: () => { },
        onError: () => { },
    })

    const { data: { data = {} } = {}, isLoading, isFetching, refetch: fetch } = query

    return { data, isLoading, isFetching, fetch }
}

export default useGetUserData;
