import { Button, Input } from "antd";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "redux/action/custom-popup/customPopupAction";
import { CustomPopup } from "share/components";
import { POPUP_ID } from "share/constantShared";
import { useUpdateUserInfo } from "../hook/useUpdateUserInfo";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';

const PopupChangeNickName = ({ socket }) => {
  const dispatch = useDispatch();
  const { userInfo, mySeat } = useSelector((state) => state.room);

  const { mutate, isLoading } = useUpdateUserInfo();

  const method = useForm({
    mode: "onChange",
    defaultValues: {
      nickName: userInfo?.nickName ?? "",
    },
    resolver: yupResolver(yup.object().shape({
      nickName: yup.string().required()
    }))
  });

  const {
    handleSubmit,
    reset,
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = method;
  
  useEffect(()=> {
    if(!_.isEmpty(userInfo)) {
      setValue("nickName", userInfo?.nickName)
    }
  }, [userInfo])

  const handleSubmitData = async (data) => {
    await mutate({
      Data: {
        nickName: data.nickName,
        uid: parseInt(userInfo?.uid),
      },
      onSuccess: (res) => {
        toast.success("Change success");
        socket.send(
          JSON.stringify({
            FunctionCode: "ChangeNickName",
            FFunctionCode: "",
            Data: {
              Player: {
                uid: userInfo.uid,
                userName: userInfo.userName,
                nickName: userInfo.nickName,
              },
              NickName: data.nickName
            },
          })
        );
      },
      onError: (err) => {
        toast.error("Fail");
      },
    });
  };

  const handleCancel = () => {
    dispatch(closePopup({ id: POPUP_ID.CHANGE_NICKNAME }));
  };

  return (
    <CustomPopup
      id={POPUP_ID.CHANGE_NICKNAME}
      style={{ padding: 0 }}
      closeIcon={false}
      className="no-padding"
    >
      <FormProvider {...method}>
        <form
          className="bg-[#13563B] p-[10px] border-solid border-[#FFDF00] border-[2px] rounded-[10px] text-white"
          onSubmit={handleSubmit(handleSubmitData)}
        >
          <h4 className="lg:text-[15px] 2xl:text-[18px] mb-[15px] text-[#FFDF00]">
            Edit Nickname
          </h4>
          <div className="flex flex-col items-end">
            <div className="flex flex-col items-start">
              {/* <h5 className="lg:text-[13px] 2xl:text-[15px]">Nickname:</h5> */}
              <Controller
                name="nickName"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      className={`p-[10px_15px] ${errors["nickName"] ? "input-error" : ""}`}
                      placeholder="Input your nickname..."
                    />
                  );
                }}
              />
            </div>
            <div className="grid grid-cols-2 gap-[10px] w-full">
              <Button
                className="mt-[20px] bg-[#C83737] font-bold text-[#fff] w-full border-none rounded-[20px]"
                type="default"
                htmlType="button"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="mt-[20px] bg-[#FFDF00] font-bold text-[#000] w-full border-none rounded-[20px]"
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </CustomPopup>
  );
};

export default PopupChangeNickName;
