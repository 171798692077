import { useQuery } from "react-query"
import axiosWrapper from "service/jwtService/jwtService"

const getData = async (params) => {

    if (!params) return

    return await axiosWrapper({
        url: `/rooms/findRoomById?roomId=${params}`,
        method: 'get'
    })

}

const useGetRoomDetail = (params) => {
    const query = useQuery([`/rooms/findRoomById`, params], () => getData(params), {
        keepPreviousData: false,
        retry: true,
        refetchOnWindowFocus: false,
        onSuccess: () => { },
        onError: () => { },
    })

    const { data: { data = {} } = {}, isLoading, isFetching, refetch: fetch } = query

    return { data, isLoading, isFetching, fetch }
}

export default useGetRoomDetail;
