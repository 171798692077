import React, { useEffect } from "react";
import { CustomPopup } from "share/components";
import { homePopupID } from "../../constant";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Avatar, Input } from "antd";
import _ from "lodash";
import toast from "react-hot-toast";
import { useChangePassword } from "main/admin/userMgt/hook/useChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "redux/action/custom-popup/customPopupAction";

const ChangePassword = () => {
  const { userInfo } = useSelector((state) => state.home);
  const { mutate, isLoading } = useChangePassword();
  const dispatch = useDispatch();

  const defaultValues = {
    confirmPassword: "",
    oldPassword: "",
    password: "",
  };
  const method = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        confirmPassword: yup
          .string()
          .required()
          .oneOf([yup.ref("password")], "Not match password"),
        oldPassword: yup.string().required(),
        password: yup.string().required(),
      })
    ),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = method;

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const changePassword = async (data) => {
    await mutate({
      Data: {
        uid: parseInt(userInfo.uid),
        confirmPassword: data.confirmPassword,
        oldPassword: data.oldPassword,
        password: data.password,
      },
      onSuccess: async (res) => {
        toast.success("Change password success");
        reset();
        dispatch(closePopup({ id: homePopupID.changePassword }));
      },
      onError: (err) => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };

  const handleCancel = () => {
    reset();
    dispatch(closePopup({ id: homePopupID.changePassword }));
  };

  return (
    <CustomPopup
      className="no-padding"
      title={false}
      id={homePopupID.changePassword}
    >
      <FormProvider {...method}>
        <form
          onSubmit={handleSubmit(changePassword)}
          className="rounded-[20px] border-solid border-[2px] border-[#FFDF00] p-[10px] lg:p-[20px] relative w-[85vw] lg:w-[350px] 2xl:w-[400px] flex flex-col items-center box-border"
        >
          <div
            className="absolute top-[-5px] left-[50%] rounded-[50%]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <Avatar
              size={60}
              style={{ background: "#979797" }}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-[6] lg:size-[7] 2xl:size-9"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              }
            />
          </div>
          <div className="grid grid-cols-1 gap-[10px] w-[80%] my-[10px] lg:my-[20px]">
            <div className="flex flex-col">
              <h5 className="text-[12px] lg:text-[13px] 2xl:text-[15px] mb-[5px]">
                Current password
              </h5>
              <Controller
                name="oldPassword"
                control={control}
                render={({ field }) => {
                  return (
                    <Input.Password
                      {...field}
                      className={`lg:p-[5px] 2xl:p-[7px] ${
                        errors["oldPassword"] ? "input-error" : ""
                      }`}
                    />
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-[12px] lg:text-[13px] 2xl:text-[15px] mb-[5px]">
                New password
              </h5>
              <Controller
                name="password"
                control={control}
                render={({ field }) => {
                  return (
                    <Input.Password
                      {...field}
                      className={`lg:p-[5px] 2xl:p-[7px] ${
                        errors["password"] ? "input-error" : ""
                      }`}
                    />
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-[12px] lg:text-[13px] 2xl:text-[15px] mb-[5px]">
                Confirm password
              </h5>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => {
                  return (
                    <Input.Password
                      {...field}
                      className={`lg:p-[5px] 2xl:p-[7px] ${
                        errors["confirmPassword"] ? "input-error" : ""
                      }`}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-[20px] w-full mt-[15px] lg:mt-[20px]">
            <button
              type="button"
              onClick={handleCancel}
              className="bg-[#C83737] text-[#fff] rounded-[30px] outline-none border-none p-[10px] 2xl:p-[15px_10px]"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#13563B] text-[#fff] rounded-[30px] outline-none border-none p-[10px] 2xl:p-[15px_10px]"
            >
              Save
            </button>
          </div>
        </form>
      </FormProvider>
    </CustomPopup>
  );
};

export default ChangePassword;
