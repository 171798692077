import { Popover } from "antd";
import React from "react";

const Help = ({ data }) => {
  const HelpContent = () => {
    return (
      <div
        className="w-[200px] lg:w-[400px] 2xl:w-[550px] h-[140px] lg:h-[270px] 2xl:h-[370px] rounded-[7px] bg-cover"
         style={{backgroundImage: 'url(/images/helper.jpg)'}}
      />
    );
  };

  return (
    <>
      <Popover
        content={<HelpContent />}
        // title="Sound setting"
        trigger="click"
        className="toolbarPopover"
      >
        <button
          className="bg-transparent outline-none border-none w-[15px] h-[15px] lg:w-[18px] lg:h-[18px] 2xl:w-[20px] 2xl:h-[20px] "
          // onClick={handleOnClick}
        >
          <img src={data.icon} className="w-full h-full object-cover" />
        </button>
      </Popover>
    </>
  );
};

export default Help;
