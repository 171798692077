import { formatDate } from "share/function";
import { UserStatus } from "share/helperCommon";

export const columnsUserLogData = () => [
  {
    title: "User ID",
    dataIndex: "userId",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Create At",
    dataIndex: "createAt",
    render: (createAt) => `${formatDate(createAt)}`,
  },
  {
    title: "IP User",
    dataIndex: "ipUser",
  },
  //   {
  //     title: "Result Setting",
  //     dataIndex: "setting",
  //     align: 'center',
  //     render: (text, record) => (
  //       <div className="grid grid-cols-2 gap-[7px] w-full">
  //         <button
  //           className="rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] lg:text-[9px] 2xl:text-[11px] p-[7px] text-nowrap box-border flex items-center justify-center"
  //           onClick={() => handleActived(record, true)}
  //         >
  //           승인
  //         </button>
  //         <button
  //           className="rounded-[20px] border-none outline-none bg-[#C83737] text-[#fff] lg:text-[9px] 2xl:text-[11px] p-[7px] text-nowrap box-border flex items-center justify-center"
  //           onClick={() => handleActived(record, false)}
  //         >
  //           거절
  //         </button>
  //       </div>
  //     ),
  //   },
];
