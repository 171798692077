import { useMutation } from "react-query";
import axiosWrapper from "service/jwtService/jwtService";

const postData = async (data) => {
    return await axiosWrapper({
      url: `/otp/create`,
      method: "post",
      data: data,
    });
  };
  
  export const useGetOTP = () => {
    return useMutation(postData, {
  
      onSuccess: (_, { onSuccess, status }) => {
        onSuccess(_)
      },
      onError: (_, { onError, status }) => {
        onError(_);
      },
      
    });
  };