import React from "react";

const StartGame = () => {
  return (
    <div
      className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.6)] z-[5] startGame"
      id="startGame"
    >
      <div className="w-full h-full flex items-center justify-center">
        <span>START</span>
        {/* <span>S</span>
        <span>T</span>
        <span>A</span>
        <span>R</span>
        <span>T</span> */}
      </div>
    </div>
  );
};

export default StartGame;
