import { Switch, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getPlayingStatus } from "share/function";
import useGetRoomDetail from "../../hook/useGetRoomDetail";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTurn,
  endGame,
  receiveCardOnSeat,
  resetGame,
  updateBetOnSeat,
  updateFirstBetOnSeat,
  updateRoom,
  updateSeatAtPosition,
  updateSeatOnRoom,
} from "redux/action/roomAction";
import { useNavigate } from "react-router-dom";

const RoomDetail = ({ data, showSlice, onShowSlice }) => {
  let timer;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const winnerRef = useRef(null);
  const [isDisplayTimerGameStart, setIsDisplayTimerGameStart] =
    useState("none");
  const [socket, setSocket] = useState();
  const [socket2, setSocket2] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [timeGameStart, setTimeGameStart] = useState(3);

  const { userInfo } = useSelector((state) => state.home);
  const {
    roomInfo,
    userPlace,
    gameStart,
    presentTurn,
    mySeat,
    totalBet,
    winnerPlayer,
  } = useSelector((state) => state.room);

  const {
    data: dataRoomAPI,
    isLoading: isLoadingRoom,
    fetch,
  } = useGetRoomDetail(data?.roomId);

  const handleBack = () => {
    // socket.emit("leave_room", { roomId: data?.roomId });
    onShowSlice((prev) => {
      return {
        open: false,
        initData: null,
      };
    });
  };

  const onChange = (data) => {
    // setIsDisabled(true);
    // setTimeout(() => {
    //   setIsDisabled(false);
    // }, 3000);
  };

  useEffect(() => {
    if (!_.isEmpty(dataRoomAPI)) {
      let socket = new WebSocket(dataRoomAPI?.roomUrl);
      // let socket = new WebSocket("ws://192.168.100.66:8080");
      setSocket(socket);
    }
  }, [dataRoomAPI]);

  const dataRoom = useMemo(() => {
    if (!_.isEmpty(roomInfo)) {
      return {
        ...dataRoomAPI,
        roomPlayer: roomInfo,
        roomMember: roomInfo,
      };
    }
  }, [dataRoomAPI, roomInfo]);

  useEffect(() => {
    if (socket) {
      socket.onopen = (e) => {
        socket.send(
          JSON.stringify({
            FunctionCode: "AdminJoinRoom",
            FFunctionCode: "",
            Data: {
              PlayerName: userInfo.userName,
              PlayerId: userInfo.uid,
              PlayerType: userInfo?.roleId,
              TotalAmount: 0.0,
            },
            Info: null,
            OData: null,
          })
        );
      };

      socket.onmessage = function (evt) {
        const result = JSON.parse(evt.data);
        // console.log("result", result);
        switch (result.FFunctionCode) {
          case "JoinRoom": {
            switch (result.FunctionCode) {
              case "Completed": {
                setIsLoading(false);
                let socket2 = new WebSocket(dataRoomAPI?.streamVideoUrl);
                setSocket2(socket2);
                break;
              }
              case "Error": {
                toast.error("Can't join this room", 2000);
                setTimeout(() => {
                  socket.send(
                    JSON.stringify({
                      FunctionCode: "Exit",
                      FFunctionCode: "",
                      Data: null,
                    })
                  );
                  navigate("/");
                }, 2000);
                break;
              }
              case "SyncAllSeat": {
                dispatch(
                  updateRoom(
                    result?.Data?.map((seat) => {
                      return { ...seat, card: [], mySeat: false };
                    })
                  )
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case "Call": {
            switch (result.FunctionCode) {
              case "SyncSeat": {
                dispatch(updateBetOnSeat(result.Data));
              }
            }
          }
          case "Check": {
            switch (result.FunctionCode) {
              case "Check":
              case "SyncSeat": {
                setIsDisplayTimerGameStart("none");
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                // dispatch(updateCheckSatus(true));
                break;
              }
              default:
                break;
            }
          }
          case "Fold": {
            switch (result.FunctionCode) {
              case "Fold": {
                setIsDisplayTimerGameStart("none");
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                break;
              }
              case "SyncSeat": {
                setIsDisplayTimerGameStart("none");
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                break;
              }
              default:
                break;
            }
          }
          case "TakeSeat": {
            dispatch(updateSeatOnRoom(result.Data));
            break;
          }
          case "Raise": {
            switch (result.FunctionCode) {
              case "Raise": {
                dispatch(updateBetOnSeat(result.Data));
                break;
              }
              case "SyncSeat": {
                dispatch(updateBetOnSeat(result.Data));
                break;
              }
              default:
                break;
            }
          }
          case "ResetGame": {
            dispatch(resetGame());
            break;
          }
          default: {
            switch (result.FunctionCode) {
              case "TakeSeat": {
                dispatch(updateSeatOnRoom({ ...result.Data, mySeat: true }));
                break;
              }
              case "SyncSeat": {
                if (result.Data.HaveClient) {
                  if (result.Data.Player?.PriceOnTable === 0) {
                    toast.success(
                      `User ${result.Data.Player?.userName} take seat ${result.Data.PositionCode}`
                    );
                  }
                } else {
                  toast.error(
                    `User ${result.Data.Player?.userName} leave seat ${result.Data.PositionCode}`
                  );
                }
                dispatch(updateSeatOnRoom({ ...result.Data }));
                break;
              }
              case "ReceiveCard": {
                dispatch(receiveCardOnSeat(result.Data));
                break;
              }
              case "GambleNew": {
                result.Data?.forEach((seat) => {
                  if (!_.isEmpty(seat)) {
                    dispatch(
                      updateSeatAtPosition({
                        PositionCode: seat.PositionCode,
                        PositionType: seat.PositionType,
                        PositionStatus: seat.PositionStatus,
                        Player: seat.Player,
                        NewGame: true,
                      })
                    );
                  }
                });

                break;
              }
              case "FirstBet": {
                dispatch(updateFirstBetOnSeat(result.Data));
                break;
              }
              case "YourTurn": {
                dispatch(changeTurn(result.Data));
                setIsDisplayTimerGameStart("none");
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                // handlePlayGame();
                break;
              }
              case "WinnerPlayer": {
                setIsLoading(false);
                dispatch(endGame(result.Data));
                // handleEndGame();
                break;
              }
              case "ReceiveMoney": {
                // dispatch()
                break;
              }
              case "WaitingCheckingWinner": {
                setIsLoading(true);
                break;
              }
              default:
                break;
            }
          }
        }
      };
    }
  }, [socket]);

  useEffect(() => {
    if (socket2) {
      const config = {
        // iceServers: [
        //   {
        //     urls: "turn:turn.anyfirewall.com:443?transport=tcp",
        //     username: "webrtc",
        //     credential: "webrtc",
        //   },
        // ],
        iceServers: !_.isEmpty(dataRoomAPI?.rTCIceServer)
          ? JSON.parse(dataRoomAPI?.rTCIceServer)?.map((cf, index) => {
              return {
                urls: cf?.Url,
                ...(cf?.UserName && { username: cf?.UserName }),
                ...(cf?.Password && { credential: cf?.Password }),
              };
            })
          : [],
        iceTransportPolicy: dataRoomAPI?.rTCIceTransportPolicy,
      };

      var pc = new RTCPeerConnection(config);

      pc.ontrack = (evt) => {
        setIsLoading(false);
        if (winnerRef?.current) {
          winnerRef.current.srcObject = evt.streams[0];
        }
      };
      socket2.onmessage = async function (evt) {
        if (/^[\{"'\s]*candidate/.test(evt.data)) {
          pc.addIceCandidate(JSON.parse(evt.data));
        } else {
          await pc.setRemoteDescription(
            new RTCSessionDescription(JSON.parse(evt.data))
          );
          pc.createAnswer()
            .then((answer) => pc.setLocalDescription(answer))
            .then(() => socket2.send(JSON.stringify(pc.localDescription)));
        }
      };
    }
  }, [socket2]);

  const handlePlayGame = () => {
    // let playerInSeat = dataRoom?.roomPlayer?.filter((player) => player.uid);
    // if (playerInSeat?.length < 3) {
    //   toast.error("시작하기에 플레이어 수가 충분하지 않습니");
    // } else {
    //   // socket.emit("start_game", dataRoom);
    //   setIsDisplayTimerGameStart("block");
    //   let seconds = 3;

    //   timer = setInterval(() => {
    //     seconds--;
    //     setTimeGameStart(seconds);

    //     if (seconds < 0) {
    //       fetch();
    //       setIsDisplayTimerGameStart("none");
    //       setTimeGameStart(3);
    //       clearInterval(timer);
    //     }
    //   }, 1000);
    // }
    toast.success("Function is developing...");
  };

  const handleEndGame = () => {};

  const renderRoleSeat = (item) => {
    let role = item.PositionType;
    switch (role) {
      case 0:
        return;
      case 1:
        return <img src="/images/DealerDark.png" className="w-[60px] h-fit" />;
      case 2:
        return <img src="/images/sb.png" className="w-[60px] h-fit" />;
      case 3:
        return <img src="/images/bb.png" className="w-[60px] h-fit" />;
      case 4:
        return;
    }
  };

  return (
    <div className="h-full w-full flex items-center flex-col relative">
      <div className="bg-[gray] w-full flex items-center p-[7px_20px] box-border">
        <button onClick={handleBack}>Back</button>
        <div className="px-[10px] flex items-center">
          <img src="/images/livegame.png" className="w-[30px] h-[30px] mx-[5px]" />
          <h5 className="text-[15px] lg:text-[18px] text-center 2xl:text-[22px] text-[#C83737] italic  mx-[5px]">
            {dataRoom?.roomName}
          </h5>
          <div className="text-[#13563B] font-bold mx-[5px]">
            <span>$1</span>/<span>$2</span>
          </div>
          <div className="mx-[5px]">{getPlayingStatus(dataRoom?.roomStatus)}</div>
          <div className="flex items-center  mx-[5px]">
            <img src="/images/icon10.png" className="w-[30px]" />
            <div>
              <span>
                {
                  dataRoom?.seatedPlayer
                }
                /7
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full h-full p-[10px]">
        <div className="relative h-full w-[85%] box-border border-[2px] border-solid border-[#FFDF00] rounded-[10px]">
          <video
            ref={winnerRef}
            id="videoCtl"
            autoPlay
            muted
            preload="auto"
            src=""
            className="w-full h-fit lg:h-full absolute object-fill  top-0 left-0 z-[1] rounded-[10px]"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
          />
        </div>
        <div className="flex flex-col items-end justify-end w-[15%] p-[0px_10px] box-border">
          <div className="bottom-0 right-0 grid grid-cols-1 gap-[10px]">
            <button
              type="button"
              onClick={() => {}}
              className="cursor-pointer w-[130px] p-[12px_10px] rounded-[20px] border-none outline-none  bg-[#7D4302] text-[#fff] flex items-center justify-center text-[15px] font-bold btn-shadow"
            >
              게임방 닫기
            </button>
            <button
              type="button"
              onClick={() => {}}
              className="cursor-pointer w-[130px] p-[12px_10px] rounded-[20px] border-none outline-none  bg-[#fff] text-[#000] flex items-center justify-center text-[15px] font-bold btn-shadow"
            >
              중지
            </button>
            <Tooltip placement="left" title={`${dataRoom?.roomTime} seconds`}>
              <button
                type="button"
                onClick={handlePlayGame}
                className="cursor-pointer w-[130px] p-[12px_10px] rounded-[20px] border-none outline-none  bg-[#FFDF00] text-[#000] flex items-center justify-center text-[15px] font-bold btn-shadow"
              >
                타이머
              </button>
            </Tooltip>
            <button
              type="button"
              onClick={() => {}}
              className="cursor-pointer w-[130px] p-[12px_10px] rounded-[20px] border-none outline-none  bg-[#C83737] text-[#fff] flex items-center justify-center text-[15px] font-bold btn-shadow"
            >
              카드 노출
            </button>
          </div>
        </div>
      </div>
      <div
        className="fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.5)] z-[4]"
        style={{ display: isDisplayTimerGameStart }}
      >
        <div className="flex items-center justify-center w-full h-full">
          <span
            id="timerGameStart"
            className="text-[150px] font-bold text-[#FFDF00]"
          >
            {timeGameStart}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RoomDetail;
