import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const menu = JSON.parse(localStorage.getItem("menuActived"));
  const { userInfo } = useSelector((state) => state.home);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    navigate("/login");
  };

  return (
    <div className="w-full p-[10px_20px] bg-[#ABABAB] flex justify-between box-border">
      <h5 className="font-bold text-[22px] ">{menu?.menuTitle}</h5>
      <div className="flex items-center">
        <div className="text-[11px] lg:text-[15px] 2xl:text-[17px] cursor-pointer flex items-center mr-[10px]">
          <div className="w-[10px] h-[10px] lg:w-[15px] lg:h-[15px] rounded-[50%] bg-[#28f354dc] mr-[10px]"></div>
          <span className="mr-[10px] font-bold">{userInfo?.nickName}</span>
        </div>
        <button
          className="rounded-[20px] border-none outline-none p-[5px_10px]"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Navbar;
