import axios from "axios";

const axiosWrapper = axios.create({
    // baseURL: 'http://192.168.100.203:8086',
    baseURL: 'https://api.clark.thlone.vn'
    // baseURL: 'http://localhost:3001'
});

axiosWrapper.interceptors.request.use((config)=> {

    const access_token = localStorage.getItem("jwt");
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
  
    return config;
    
  })
  
  // axiosWrapper.defaults.headers.Authorization = `Bearer ${access_token}`;
  // if (access_token) {
  //   axiosWrapper.defaults.headers.Authorization = `Bearer ${access_token}`;
  // } else {
  //   delete axiosWrapper.defaults.headers.Authorization;
  // }
  
  // response parse
  axiosWrapper.interceptors.response.use(
    (response) => {
  
      return response;
    },
    (error) => {
      if (error.response) {
        if (error.response?.status === 401) {
          // localStorage.removeItem("jwt");
          // window.location.replace("/user/redirect-login");
          return;
        }
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject(error);
      }
    }
  );
  
  export default axiosWrapper;