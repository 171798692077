import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useLogin } from "./hook/useLogin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { loginSuccess } from "redux/action/homeAction";

const Login = () => {
  const { mutate, isLoading } = useLogin();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const method = useForm({
    mode: "onSubmit",
    defaultValues: {
      userName: "",
      password: "",
    },
  });

  const {
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { error },
    register,
    control,
  } = method;

  const handleSubmitForm = async (data) => {
    await mutate({
      ...data,
      onSuccess: (msg) => {
        localStorage.setItem("jwt", msg.data.token.token);
        // socket.connect();
        dispatch(loginSuccess(msg.data.user));
        toast.dismiss();
        toast.success("Login success");
        if (msg?.data?.user) {
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 1000);
        }
      },
      onError: (msg) => {
        toast.dismiss();
        toast.error(msg.message);
      },
    });
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <div className="w-full h-full">
      <div className="flex flex-col items-center justify-center lg:justify-top w-full h-full">
        <img
          src="/images/livegame.png"
          className="h-auto w-[80px] lg:w-[120px] 2xl:w-[180px] lg:m-[10px_0px_30px_0px] 2xl:m-[30px_0px_50px_0px]"
        />
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="rounded-[20px] p-[10px] lg:p-[10px_20px_20px_20px] 2xl:p-[20px_40px_40px_40px] bg-[#13573c] lg:grid lg:grid-cols-5 lg:gap-[15px] 2xl:gap-[20px] w-[70vw] 2xl:w-[50vw] h-fit">
              <div className="col-span-2">
                <img
                  src="/images/livegame3.png"
                  className="hidden lg:block lg:w-[280px] 2xl:w-[350px] h-auto object-fit object-left"
                />
                <div className="grid grid-cols-1 gap-[10px] lg:gap-[10px] 2xl:gap-[25px] mt-[20px]">
                  <Controller
                    name="userName"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="ID(아이디)"
                        className="lg:p-[10px] 2xl:p-[15px]"
                      />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input.Password
                        {...field}
                        placeholder="PW(비밀번호)"
                        className="lg:p-[10px] 2xl:p-[15px]"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    )}
                  />
                  <button
                    type="submit"
                    className="cursor-pointer w-[50%] lg:w-[170px] 2xl:w-[200px] p-[7px] lg:p-[15px_7px] 2xl:p-[15px_10px] rounded-[30px] border-none outline-none m-[0_auto] bg-[#C83737] text-[#fff] flex items-center justify-center mt-[10px]"
                  >
                    Login(로그인)
                  </button>
                  <button
                    type="button"
                    className="cursor-pointer  w-[50%] p-[7px] lg:w-[170px] 2xl:w-[200px] lg:p-[15px_7px] 2xl:p-[15px_10px] rounded-[30px] border-none outline-none m-[0_auto] bg-[#FFDF00] flex items-center justify-center"
                    onClick={handleSignUp}
                  >
                    Sign up(등록)
                  </button>
                </div>
              </div>
              <div className="hidden lg:block col-span-3 relative">
                <img
                  src="/images/cardlogin.png"
                  className="absolute top-[40%] left-[35%] lg:w-[350px] 2xl:w-[450px] z-[3]"
                  style={{ transform: "translate(-20%, -40%) rotate(18deg)" }}
                />
                <img
                  src="/images/coinlogin.png"
                  className="absolute top-[40%] left-[10%] lg:w-[200px] 2xl:w-[300px] z-[4]"
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Login;
