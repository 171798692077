const initialValue = {
  isConfirm: {
    status: "done",
    method: () => {},
  },
  userInfo: {},
  roomData: [],
};

export const homeReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "SET_CONFIRM": {
      state.isConfirm = action.payload;
      return { ...state };
    }
    case "LOGIN_SUCCESS": {
      state.userInfo = action.payload;
      return { ...state };
    }
    case "UPDATE_USER_INFO": {
      state.userInfo = { ...state.userInfo, ...action.payload };
      return { ...state };
    }
    case "UPDATE_ROOM": {
      state.roomData = action.payload;
      return { ...state };
    }
    case "CHANGE_DATA_ROOM_BY_ID": {
      const { action: actionType, dataRoom } = action.payload;
      let index = state.roomData?.findIndex(
        (room) => room.roomId == dataRoom.roomId
      );
      if (index > -1) {
        switch (actionType) {
          case "update_client": {
            state.roomData[index].seatedPlayers = dataRoom.seatedPlayers;
            break;
          }
          case "update_status": {
            state.roomData[index].dealerStatus = dataRoom.dealerStatus;
            break;
          }
          default:
            break;
        }
      }

      return { ...state };
    }
    default:
      return { ...state };
  }
};
