import { socket } from "context/socket";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getPlayingStatus } from "share/function";

const RoomItem = ({ data, onShowSlice, showSlice }) => {
  return (
    <div
      onClick={onShowSlice}
      className="flex items-center justify-between rounded-[20px] bg-[#fff] border-[1px] border-solid border-[#ECB176] cursor-pointer"
      style={{
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      }}
    >
      <div className="w-[60%] relative h-full">
        <img
          // src={data.roomImg}
          src={`/images/Picture${
            [0, 3, 4].includes(data?.dealerStatus) ? "2" : "1"
          }.png`}
          className={`object-fit h-full w-full rounded-[20px]`}
          style={{
            filter: `${
              [0, 3, 4].includes(data?.dealerStatus) ? "grayscale(0.7)" : "none"
            }`,
          }}
        />
        {[1, 2].includes(data?.dealerStatus) ? (
          <div className="livenow absolute right-[10px] top-[10px]">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <h5>4</h5>
          </div>
        ) : (
          <img
            src="/images/lock.png"
            className="lg:w-[25px] 2xl:w-[30px] h-fit absolute left-[10px] top-[10px]"
          />
        )}
      </div>
      <div className="flex flex-col items-center justify-evenly lg:w-[45%] 2xl:w-[40%] h-full lg:py-[5px] 2xl:py-[10px]">
        <h5 className="flex items-center">
          <img src="/images/livegame.png" className="h-fit w-[40px]" />
          <span className="text-[15px] lg:text-[18px] text-center 2xl:text-[22px] text-[#C83737] italic">
            {data.roomName}
          </span>
        </h5>
        <div className="grid grid-cols-1 lg:gap-[5px] 2xl:gap-[10px] w-full lg:p-[5px_10px] 2xl:p-[10px] box-border">
          <div className="flex items-center justify-between 2xl:text-[13px]">
            <span>Stakes:</span>
            <div className="text-[#13563B] font-bold">
              <span>${data.roomStakeFrom ?? 1}</span>
              <span>/</span>
              <span>${data.roomStakeTo ?? 2}</span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span>Status:</span>
            <span className="font-bold">
              {getPlayingStatus(data.dealerStatus)}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span>Player:</span>
            <div className="font-bold">
              <span>{data?.seatedPlayers}</span>
              <span>/7</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomItem;
