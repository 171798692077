import React from "react";
import { CustomPopup } from "share/components";

const DialogConfirm = ({ id, render }) => {
  return (
    <CustomPopup
      id={id}
      style={{ padding: 0 }}
      closeIcon={false}
      className="no-padding"
    >
      {render}
    </CustomPopup>
  );
};

export default DialogConfirm;
