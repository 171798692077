export const joinRoom = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "JOIN_ROOM",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const takeSeat = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "TAKE_SEAT",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const leaveSeat = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "LEAVE_SEAT",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const leaveRoom = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "LEAVE_ROOM",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateRoom = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_ROOM",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateSeatOnRoom = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_SEAT_ON_ROOM",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateSeatNextTurn = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_SEAT_NEXT_TURN",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateBetOnSeat = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_BET_ON_SEAT",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const clearMyTurn = () => {
  try {
    return { type: "CLEAR_MY_TURN" };
  } catch (err) {
    return err;
  }
};

export const updateFirstBetOnSeat = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_FIRST_BET_ON_SEAT",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateSeatAtPosition = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_SEAT_AT_POSITION",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const receiveCardOnSeat = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "RECEIVE_CARD_ON_SEAT",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const changeTurn = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CHANGE_TURN",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateMyTurn = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_MY_TURN",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateCheckSatus = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_CHECK_STATUS",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const startGame = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "START_GAME",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const endGame = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "END_GAME",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const showPlayerHandRank = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "SHOW_PLAYER_HAND_RANK",
      payload: data,
    });
  } catch (err) {
    return err;
  }
}

export const resetGame = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "RESET_GAME",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const exitRoom = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "EXIT_ROOM",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateClientOnSeat = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_CLIENT_ON_SEAT",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateUserInfoOnRoom = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_USER_INFO_ON_ROOM",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const setBetOnSeatReserved = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_BET_ON_SEAT_RESERVED",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateShowCallCard = () => {
  try {
    // dispatch({
    return { type: "UPDATE_SHOW_ALL_CARD" };
    // })
  } catch (err) {
    return err;
  }
};


export const updateMySeat = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_MY_SEAT",
      payload: data
    })
  } catch (err) {
    return err;
  }
}

export const cancelGame = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CANCEL_GAME",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateOptionToolbar = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_OPTION_TOOLBAR",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const reconnectGame = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "RECONNECT_GAME",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const clearTurnRoomInfo = () => {
  try {
    return { type: "CLEAR_TURN_ROOM" };
  } catch (err) {
    return err;
  }
};

export const updateReaction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_REACTION",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateChatOnRoom = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_CHAT_ON_ROOM",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateScreenMySeat = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_SCREEN_MY_SEAT",
      payload: data
    })
  } catch (err) {
    return err;
  }
}

export const updateCaptureCard = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_CAPTURE_CARD",
      payload: data
    })
  } catch(err) {
    return err;
  }
}

export const updateChat = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_OPEN_CHAT",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const haveANewRound = () => {
  return {
    type: "NEW_ROUND"
  }
}