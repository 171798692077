import React, { useContext, useEffect, useState } from "react";
import useGetRoom from "../hook/useGetRoom";
import { useFilters } from "share/function";
import RoomItem from "./RoomItem";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import FormCreateRoom from "./FormCreateRoom";
import { useDispatch, useSelector } from "react-redux";
import { changeDataRoomByID, updateRoom } from "redux/action/homeAction";
import _ from "lodash";
import { SocketAdminProvider } from "fuse/layout-admin/layout-master";

const RoomContent = ({ data, onShowSlice, showSlice, open, setOpen }) => {
  const { socket: socketAdmin } = useContext(SocketAdminProvider);

  const defaultValues = {
    roomPrice: data.roomPrice,
  };

  const dispatch = useDispatch();

  const { count, setCount, filters, onFilter } = useFilters(defaultValues);

  const { data: dataRoom, isLoading, isFetching, fetch } = useGetRoom(filters);

  const { roomData } = useSelector((state) => state.home);

  const handleShowSlice = (data) => {
    onShowSlice((prev) => {
      return {
        open: true,
        initData: data,
      };
    });
  };

  useEffect(() => {
    if (!_.isEmpty(dataRoom)) {
      dispatch(updateRoom(dataRoom?.data));
    }
  }, [dataRoom]);

  useEffect(() => {
    if (socketAdmin) {
      socketAdmin.onmessage = async (evt) => {
        const result = JSON.parse(evt.data);
        switch (result.FunctionCode) {
          case "refresh_room": {
            await fetch();
            break;
          }
          case "sync_update_room": {
            const { Data } = result;
            dispatch(changeDataRoomByID(Data));
            break;
          }
          default:
            break;
        }
      };
    }
  }, [socketAdmin]);

  const renderRoomItem = () => {
    return dataRoom?.data?.map((room, index) => {
      return (
        <RoomItem
          data={room}
          key={index}
          showSlice={showSlice}
          onShowSlice={() => handleShowSlice(room)}
        />
      );
    });
  };

  useLoadingEffect(isLoading);

  return (
    <>
      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[10px] lg:gap-[15px] 2xl:gap-[40px] lg:py-[10px] 2xl:py-[20px]">
        {renderRoomItem()}
      </div>
      <FormCreateRoom open={open} setOpen={setOpen} />
    </>
  );
};

export default RoomContent;
