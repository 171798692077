import { socket } from "context/socket";
import React from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPlayingStatus } from "share/function";
import { UserStatus } from "share/helperCommon";

const Room = ({ data }) => {
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.home);

  const joinRoom = () => {
    toast.dismiss();
    if ([0, 3, 4].includes(data?.dealerStatus)) {
      return toast.error("This room is blocked!");
    }
    if (userInfo) {
      if (userInfo?.status == UserStatus.ACTIVATED) {
        navigate(`/room?id=${data.roomId}`);
      } 
      else {
        return toast.error("Your account must be verify before!");
      }
    }
  };

  return (
    <div
      onClick={joinRoom}
      className="m-[0_auto] flex items-center justify-between w-[90vw] lg:w-full rounded-[10px] lg:rounded-[20px] bg-[#fff] border-[1px] border-solid border-[#ECB176] cursor-pointer"
      style={{
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      }}
    >
      <div className="w-[50%] lg:w-[60%] relative h-full">
        <img
          // src={data.roomImg}
          src={`/images/Picture${
            [0, 3, 4].includes(data?.dealerStatus) ? "2" : "1"
          }.png`}
          className={`object-fit h-full w-full rounded-[10px] lg:rounded-[20px]`}
          style={{
            filter: `${
              [0, 3, 4].includes(data?.dealerStatus) ? "grayscale(0.7)" : "none"
            }`,
          }}
        />
        {[1, 2].includes(data?.dealerStatus) ? (
          <div className="livenow absolute right-[10px] top-[10px]">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <img
            src="/images/lock.png"
            className="w-[20px] lg:w-[25px] 2xl:w-[30px] h-auto absolute left-[10px] top-[10px]"
          />
        )}
      </div>
      <div className="flex flex-col items-center justify-evenly w-[50%] lg:w-[40%] h-full p-[5px] box-border">
        <h5 className="flex items-center">
          <img
            src="/images/livegame.png"
            className="h-auto w-[20px] lg:w-[40px]"
          />
          <span className="text-[15px] lg:text-[18px] text-center 2xl:text-[22px] text-[#C83737] italic">
            {data.roomName}
          </span>
        </h5>
        <div className="grid grid-cols-1 gap-[5px] lg:gap-[10px] w-full p-[5px] lg:p-[10px] box-border">
          <div className="flex items-center justify-between text-[11px] lg:text-[13px] 2xl:text-[16px]">
            <span>Stakes:</span>
            <div className="text-[#13563B] font-bold">
              <span>${data.roomStakeFrom ?? 1}</span>
              <span>/</span>
              <span>${data.roomStakeTo ?? 2}</span>
            </div>
          </div>
          <div className="flex items-center justify-between text-[11px] lg:text-[13px] 2xl:text-[16px]">
            <span>Status:</span>
            <span className="font-bold">
              {getPlayingStatus(data.dealerStatus)}
            </span>
          </div>
          <div className="flex items-center justify-between text-[11px] lg:text-[13px] 2xl:text-[16px]">
            <span>Player:</span>
            <div className="font-bold">
              <span>{data?.seatedPlayers}</span>
              <span>/7</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Room;
