import { CLOSE_POPUP, OPEN_POPUP } from "redux/types/custom-popup/type"

export const openPopup = (payload) => {
    return {
        type: OPEN_POPUP,
        payload,
    }
}

export const closePopup = (payload) => {
    return {
        type: CLOSE_POPUP,
        payload,
    }
}