import { CLOSE_POPUP, OPEN_POPUP } from "redux/types/custom-popup/type";

const initialValue = {
    // popupInfo: {
    //     id: '', //render v1 uuid
    //     isOpen: false,
    //     title: 'test',
    // },
    storePopup: []
}

export const popupReducer = (state = initialValue, action) => {
    switch(action.type) {
        case OPEN_POPUP: {
            const { id } = action.payload
            const { storePopup } = state;

            if(storePopup.length > 0){
                storePopup.forEach((store,index) => {
                  if(store.id !== id) {
                    state.storePopup.push({...action.payload, isOpen: true})
                  }else {
                    state.storePopup[index] = {...storePopup[index], isOpen: true}
                  }
                })
            }else {
                state.storePopup.push({...action.payload, isOpen: true});
            }
            return {...state}
        }

        case CLOSE_POPUP: {
            const { storePopup } = state;
            const index = storePopup.findIndex(store => store.id === action.payload.id)
            storePopup[index] = {...storePopup[index], isOpen: false}
            return {...state}
        }

        default: return {...state};
    }
} 