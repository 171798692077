export const PositionStatus = {
  TakeSeat: 1,
  Reserved: 2,
  Playing: 3,
  Paused: 4,
  Errored: 5,
  Folded: 6,
  Disconnected: 7,
};

export const UserStatus = {
  CREATED: "created",
  ACTIVATED: "activated",
  DISABLED: "disabled",
  DELETED: "deleted",
  BLOCKED: "blocked",
  HIDDEN: "hidden",
};

export const ratioRaise = [
  {
    label: "10X",
    value: 10,
  },
  {
    label: "3X",
    value: 3,
  },
  {
    label: "2X",
    value: 2,
  },
  // {
  //     label: 'POT',
  //     value: 1,
  // },
];


export const actionLogUser = {
  CREATE_ROOM: 1000,
  DELETE_ROOM: 1001,
  UPDATE_ROOM: 1002,
  CREATE_ACCOUNT: 2000,
  UPDATE_ACCOUNT: 2001,
  DELETE_ACCOUNT: 2003,
  CHANGE_PASSWORD: 2004,
  PLAY_GAME: 3000,
  DONE_GAME: 3001,
  JOIN_ROOM: 3002,
  LEAVE_ROOM: 3003,
}