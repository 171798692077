import { Input, Select } from "antd";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const CameraTab = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="grid grid-cols-1 gap-[10px] bg-[white]">
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Camera Stream</h5>
          <Controller
            control={control}
            name="cameraStream"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" />;
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Camera Stream 1</h5>
          <Controller
            control={control}
            name="cameraStream1"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" />;
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Camera S11</h5>
          <Controller
            control={control}
            name="cameraS11"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" />;
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Camera S21</h5>
          <Controller
            control={control}
            name="cameraS21"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" />;
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Camera S31</h5>
          <Controller
            control={control}
            name="cameraS31"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" />;
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Camera S41</h5>
          <Controller
            control={control}
            name="cameraS41"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" />;
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Camera S51</h5>
          <Controller
            control={control}
            name="cameraS51"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" />;
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Camera S61</h5>
          <Controller
            control={control}
            name="cameraS61"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" />;
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Camera S71</h5>
          <Controller
            control={control}
            name="cameraS71"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" />;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CameraTab;
