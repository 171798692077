import _ from "lodash";
import React, {useMemo} from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const SeatOption = ({ socket }) => {
  const { mySeat } = useSelector((state) => state.room);
  
  const disableOption = useMemo(()=> {
    return _.isEmpty(mySeat)
  }, [mySeat])
  
  const handleChooseSeatOption = (type) => {
    if(disableOption) {
      return toast.error('Người chơi phải ngồi ghế mới có thể sử dụng tính năng này')
    }
    switch(type) {
      case "buy-in": {
        toast.success('Chức năng đang phát triển!')
        break;
      }
      case "change-seat": {
        toast.success('Chức năng đang phát triển!')
        break;
      }
      default: break;
    }
  }
  return (
    <div className="bg-[#13563B] rounded-[9px]">
      <button className="flex items-center justify-start outline-none border-none p-[10px_10px_5px_10px] w-full bg-transparent" onClick={()=> {handleChooseSeatOption("buy-in")}}>
        <img src="/images/livegame.png" className="lg:w-[25px] 2xl:w-[35px]" />
        <span className="ml-[10px] text-[#FFDF00] font-[600] text-[10px] lg:text-[12px] 2xl:text-[15px]">Buy-in</span>
      </button>
      <button className="flex items-center justify-start outline-none border-none p-[5px_10px_10px_10px] w-full bg-transparent" onClick={()=> {handleChooseSeatOption("buy-in")}} >
        <img src="/images/icon8.png" className="lg:w-[20px] 2xl:w-[30px]" />
        <span className="ml-[10px] text-[#FFDF00] font-[600] text-[10px] lg:text-[12px] 2xl:text-[15px]">Change seat</span>
      </button>
    </div>
  );
};

export default SeatOption;
