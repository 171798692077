import React from "react";
import { useSelector } from "react-redux";

const CaptureCard = () => {
  const { captureCard, gameStart } = useSelector((state) => state.room);
  return (
    <>
      {captureCard.status && gameStart && (
        <div className="absolute top-[60px] right-[10px] bg-[#413a3a] z-[3] flex justify-center items-center rounded-[20px] lg:p-[7px] 2xl:p-[10px]">
          {/* {["", ""]?.map((card, index) => {
            return (
              //   <div
              //     className="lg:w-[70px] 2xl:w-[95px] lg:h-[98px] 2xl:h-[140px] border-dashed border-[#c1c1c1] border-[1px] rounded-[10px] flex items-center justify-center lg:mx-[5px] 2xl:mx-[10px]"
              //     key={index}
              //   >
              <img
                src=""
                id={`captureCard${index + 1}`}
                className={`lg:w-[135px] 2xl:w-[170px] h-full z-[3] ${index == 1 ? 'rounded-tr-[10px] rounded-br-[10px]' : 'rounded-tl-[10px] rounded-bl-[10px]'}`}
              />
              //   </div>
            );
          })} */}

          <img
            src=""
            id={`captureCard${1}`}
            className={`lg:w-[135px] 2xl:w-[170px] h-full z-[3]`}
          />
        </div>
      )}
    </>
  );
};

export default CaptureCard;
