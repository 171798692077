import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const GeneralTab = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fakeData = [
    {
      value: 1,
      label: "판돈",
    },
  ];

  const second = [
    {
      value: 1,
      label: 15,
    },
    {
      value: 2,
      label: 30,
    },
    {
      value: 3,
      label: 45,
    },
  ];

  const status = [
    {
      value: 1,
      label: "Open",
    },
    {
      value: 2,
      label: "Close",
    },
    {
      value: 3,
      label: "Pending",
    },
  ];

  const currency = [
    {
      value: "krw",
      label: "KRW",
    },
    {
      value: "usd",
      label: "USD",
    },
    {
      value: "vnd",
      label: "VND",
    },
  ];
  return (
    <div className="grid grid-cols-1 gap-[10px] bg-[white]">
      <div className="grid grid-cols-3 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Room ID</h5>
          <Controller
            control={control}
            name="roomId"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  className={`lg:p-[7px] 2xl:p-[10px] ${
                    errors["roomId"] ? "input-error" : ""
                  }`}
                />
              );
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Room Name</h5>
          <Controller
            control={control}
            name="roomName"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  className={`lg:p-[7px] 2xl:p-[10px] ${
                    errors["roomName"] ? "input-error" : ""
                  }`}
                  disabled
                />
              );
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Room Price</h5>
          <Controller
            control={control}
            name="roomPrice"
            render={({ field }) => {
              return (
                <Select
                  style={{ width: "100%" }}
                  className="no-padding"
                  options={fakeData}
                  {...field}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Room Status</h5>
          <Controller
            control={control}
            name="roomStatus"
            render={({ field }) => {
              return (
                <Select
                  className="no-padding w-full"
                  options={status}
                  defaultValue={"O"}
                  {...field}
                />
              );
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Room Time</h5>
          <Controller
            control={control}
            name="roomTime"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  className={`lg:p-[7px] 2xl:p-[10px] ${
                    errors["roomTime"] ? "input-error" : ""
                  }`}
                />
              );
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Decision Time</h5>
          <Controller
            control={control}
            name="decisionTime"
            render={({ field }) => {
              return (
                <Select
                  className="no-padding w-full"
                  options={second}
                  defaultValue={1}
                  {...field}
                />

              );
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Currency</h5>
          <Controller
            control={control}
            name="currency"
            render={({ field }) => {
              return (
                <Select
                  className="no-padding w-full"
                  options={currency}
                  defaultValue={"usd"}
                  {...field}
                />
              );
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Room Url</h5>
          <Controller
            control={control}
            name="roomUrl"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  className={`lg:p-[7px] 2xl:p-[10px] ${
                    errors["roomUrl"] ? "input-error" : ""
                  }`}
                />
              );
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Stream Video Url</h5>
          <Controller
            control={control}
            name="streamVideoUrl"
            render={({ field }) => {
              return <Input {...field} className={`lg:p-[7px] 2xl:p-[10px]`} />;
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Room Description</h5>
          <Controller
            control={control}
            name="roomDescription"
            render={({ field }) => {
              return (
                // <Select
                //   className="no-padding w-full"
                //   options={currency}
                //   defaultValue={"usd"}
                //   {...field}
                // />

                <TextArea
                  autoSize={{
                    minRows: 7,
                    maxRows: 10,
                  }}
                  {...field}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralTab;
