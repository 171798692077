import { Input, Select } from "antd";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ConfigTab = () => {
  const { control, formState: {errors} } = useFormContext();
  return (
    <div className="grid grid-cols-1 gap-[10px] bg-[white]">
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Number Player</h5>
          <Controller
            control={control}
            name="numberPlayer"
            render={({ field }) => {
              return <Input {...field} className={`lg:p-[7px] 2xl:p-[10px] ${errors["showdownTime"] ? 'input-error' : ''}`} typeof="number"/>;
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Room Timeout</h5>
          <Controller
            control={control}
            name="onRoomTimeOut"
            render={({ field }) => {
              return <Input {...field} className={`lg:p-[7px] 2xl:p-[10px] ${errors["sitOutTime"] ? 'input-error' : ''}`} typeof="number"/>;
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Showdown Time</h5>
          <Controller
            control={control}
            name="showdownTime"
            render={({ field }) => {
              return <Input {...field} className={`lg:p-[7px] 2xl:p-[10px] ${errors["showdownTime"] ? 'input-error' : ''}`} typeof="number"/>;
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Sitout Time</h5>
          <Controller
            control={control}
            name="sitOutTime"
            render={({ field }) => {
              return <Input {...field} className={`lg:p-[7px] 2xl:p-[10px] ${errors["sitOutTime"] ? 'input-error' : ''}`} typeof="number"/>;
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>Minimum Buy-in</h5>
          <Controller
            control={control}
            name="minimumBuyIn"
            render={({ field }) => {
              return <Input {...field} className={`lg:p-[7px] 2xl:p-[10px] ${errors["minimumBuyIn"] ? 'input-error' : ''}`} typeof="number"/>;
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>Maximum Buy-in</h5>
          <Controller
            control={control}
            name="maximumBuyIn"
            render={({ field }) => {
              return <Input {...field} className={`lg:p-[7px] 2xl:p-[10px] ${errors["maximumBuyIn"] ? 'input-error' : ''}`} typeof="number"/>;
            }}
          />
        </div>
      </div>
      {/* <div className="grid grid-cols-2 gap-[10px]">
        <div className="flex flex-col items-start">
          <h5>RTCIce Server</h5>
          <Controller
            control={control}
            name="rTCIceServer"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" typeof="number"/>;
            }}
          />
        </div>
        <div className="flex flex-col items-start">
          <h5>RTCIce Transport Policy</h5>
          <Controller
            control={control}
            name="rTCIceTransportPolicy"
            render={({ field }) => {
              return <Input {...field} className="lg:p-[7px] 2xl:p-[10px]" typeof="number"/>;
            }}
          />
        </div>
      </div> */}
    </div>
  );
};

export default ConfigTab;
