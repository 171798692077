import React, { useContext } from "react";
import { CustomPopup } from "share/components";
import { homePopupID } from "../../constant";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Input } from "antd";
import { useVerify } from "main/user/signup/hook/useVerify";
import { useGetOTP } from "main/user/signup/hook/useGetOTP";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import { IsMobileContext } from "fuse/layout/layout-master";

const VerifyAccount = () => {
   const {fetchUser} = useContext(IsMobileContext); 

  const { userInfo } = useSelector((state) => state.home);

  const { mutate, isLoading } = useVerify();

  const {mutate: resendOTP, isLoading: isLoadingResendOTP} = useGetOTP();

  const method = useForm({
    mode: "onChange",
    defaultValues: {
      otpCode: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        otpCode: yup.string().required().min(6),
      })
    ),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = method;

  const handleOk = async (data) => {
    const dataForm = {
      otpIdUser: parseInt(userInfo.uid),
      otpCode: data.otpCode,
    };
    await mutate({
      ...dataForm,
      onSuccess: async (res) => {
        toast.dismiss();
        toast.success("Verify succesfull");
        await fetchUser();
      },
      onError: (err) => {
        toast.dismiss();
        toast.error("OTP is incorrect or expire");
      },
    });
  };

  const handleResend = async () => {
    const dataForm = {
      otpIdUser: parseInt(userInfo.uid),
      otpValidTime: new Date(),
    };
    await resendOTP({
      ...dataForm,
      onSuccess: (res) => {
        toast.dismiss();
        toast.success("Resend successfull. Please check in your email");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error("Resend fail");
      },
    });
  };

  useLoadingEffect(isLoadingResendOTP || isLoading);

  return (
    <CustomPopup
      className="no-padding"
      title={false}
      id={homePopupID.verifyAccount}
    >
      <FormProvider {...method}>
        <form
          className="rounded-[20px] border-[2px] border-solid border-[#FFDF00] p-[10px_20px] lg:p-[20px_40px] flex flex-col items-center h-fit"
          onSubmit={handleSubmit(handleOk)}
        >
          <div className="flex flex-col items-center justify-center">
            <div className="">
              <h5 className="text-[13px] lg:text-[16px] 2xl:text-[20px] text-[#13563B] mb-[10px] lg:mb-[20px]">
                OTP Verification
              </h5>
            </div>
            <Controller
              name="otpCode"
              control={control}
              render={({ field }) => {
                return <Input.OTP length={6} {...field} />;
              }}
            />
            <button
              className="mt-[20px] p-[10px] min-w-[100px] rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] flex items-center justify-center ml-[10px]"
              type="submit"
            >
              OK
            </button>
            <div className="mt-[10px] text-center text-[10px] lg:text-[13px] 2xl:text-[16px]">
              <span>You don't receive the code? </span>
              <span
                className="font-bold underline cursor-pointer"
                onClick={handleResend}
              >
                resend
              </span>
            </div>
          </div>
        </form>
      </FormProvider>
    </CustomPopup>
  );
};

export default VerifyAccount;
