import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Filters from "./components/Filters";
import MainTable from "../shareComponent/table/MainTable";
import { useFilters } from "share/function";
import useGetUserLogsData from "./hook/useGetUserLog";
import { columnsUserLogData } from "./helper";

const UserLogMgt = () => {
  const defaultValues = {
    userId: "",
  };

  const method = useForm({
    mode: "onSubmit",
    defaultValues,
  });

  const { reset } = method;

  const { count, setCount, filters, onFilter } = useFilters(defaultValues);

  const {
    data: dataUserLogs,
    isLoading,
    fetch,
    isFetching,
  } = useGetUserLogsData(filters);

  const handleSearch = (data) => {
    onFilter({ userId: parseInt(data.userId) });
  };

  const handleRefresh = async () => {
    reset();
    onFilter({});
    await fetch();
  };

  useEffect(() => {
    let timer = setInterval(() => {
      fetch();
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="h-full w-full">
      <FormProvider {...method}>
        <Filters onSubmit={handleSearch} onRefresh={handleRefresh} />
      </FormProvider>
      <div className="h-[calc(100%_-_80px)] w-full p-[10px_0px]">
        <MainTable
          columns={columnsUserLogData()}
          data={dataUserLogs}
          rowSelect={true}
          rowKey="uid"
          pageSize={20}
        />
      </div>
    </div>
  );
};

export default UserLogMgt;
