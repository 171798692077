
export const fakeDataToolbarRoom = [
    {
      icon: "/images/icon2.png",
      type: "emoji",
    //   fnc: () => handleOptionToolbar("emoji"),
    },
    {
      icon: "/images/icon3.png",
      type: "soundSetting"
    //   fnc: () => handleOptionToolbar("mutate"),
    },
    {
      icon: "/images/icon1.png",
      type: "help",
    //   fnc: () => handleOptionToolbar("1"),
    },
    {
      icon: "/images/icon5.png",
      type: "refresh",
    //   fnc: () => handleOptionToolbar("refresh"),
    },
    {
      icon: "/images/6.png",
      type: "chat",
    //   fnc: () => handleOptionToolbar("2"),
    },
    {
      icon: "/images/icon4.png",
      type: "changeCamera",
    //   fnc: () => handleOptionToolbar("3"),
    },
  ];


export const STYLES_SEAT_COMPONENT = {
    0: 'absolute lg:bottom-[340px] 2xl:bottom-[490px] right-[1%] 2xl:right-[2%] z-[2] flex flex-col',
    1: 'absolute lg:bottom-[230px] 2xl:bottom-[300px] right-[1%] z-[2] flex flex-col',
    2: 'absolute lg:bottom-[120px] 2xl:bottom-[150px] lg:right-[4%] 2xl:right-[7%] z-[2] flex flex-col',
    3: 'absolute lg:bottom-[20px] 2xl:bottom-[15px] lg:right-[10%] 2xl:right-[13.5%] z-[2] flex flex-col',
    4: 'absolute lg:bottom-[50px] 2xl:bottom-[45px] lg:left-[10%] 2xl:left-[13%] z-[2] flex flex-col',
    5: 'absolute lg:bottom-[170px] 2xl:bottom-[210px] lg:left-[5%] 2xl:left-[6%] z-[2] flex flex-col',
    6: 'absolute lg:bottom-[280px] 2xl:bottom-[380px] lg:left-[6%] 2xl:left-[7%] z-[2] flex flex-col',
}
  

export const STYLES_USER_CARD_SEAT = {
    0: 'lg:mr-[30px] 2xl:mr-[30px]',
    1: 'lg:mr-[30px] 2xl:mr-[30px]',
    2: 'lg:mr-[30px] 2xl:mr-[30px]',
    3: 'lg:mr-[30px] 2xl:mr-[30px]',
    4: 'lg:ml-[20px] 2xl:ml-[40px]',
    5: 'lg:ml-[20px] 2xl:ml-[40px]',
    6: 'lg:ml-[20px] 2xl:ml-[40px]',
}

export const getSplitPointSeat = (index) => {
    switch(index) {
        case 1: return 4;
        case 2: return 5;
        case 3: return 6;
        case 4: return null;
        case 5: return 1;
        case 6: return 2;
        case 7: return 3;
        default: return;
    }
}


export const dialogConfirmId = {
  changeSeat: 'changeSeatRoom',
}