import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";

export const getPlayingStatus = (status) => {
  switch (status) {
    case 0:
      return "Offline";
    case 1:
      return "Ready";
    case 2:
      return "Playing";
    case 3:
      return "Paused";
    default:
      return "Errored";
  }
};

export const useFilters = (defaultValues) => {
  const [filters, setFilters] = useState(
    _.pickBy(defaultValues, (v) => v !== null && v !== undefined && v !== "")
  );

  const [count, setCount] = useState(0);

  const onFilter = (values) => {
    const params = _.pickBy(
      {
        ...values,
        count,
      },
      (v) => v !== null && v !== undefined && v !== ""
    );

    setFilters(params);
  };
  return {
    filters,
    onFilter,
    setCount,
    count,
  };
};

export const decodeToken = (token) => {
  if (!token) {
    // const origin = window.location.origin;
    // window.location.href = `${origin}/login`;
  } else {
    let decoded = jwtDecode(token);
    if (decoded?.context?.user) {
      return decoded?.context?.user;
    } else {
      toast.error("Pending data. Please login again!", 3000);
    }
  }
};

export const getImageCardByCode = (code) => {
  return `/images/52card/${code}.png`;
};

export const handleChatOpen = (open = false) => {
  const elementRoom = document.getElementById("mainRoom");
  const elementChat = document.getElementById("chatRoom");
  if (open) {
    elementRoom.style.width = "calc(100% - 300px)";
    elementChat.style.animation = "fadeOut2 0.4s ease-in-out";
    elementChat.style.animationFillMode = "forwards";
    // elementChat.style.right = "0";
    // elementChat.style.left = "unset";
  } else {
    elementRoom.style.width = "100vw";
    // elementChat.style.left = "100%";
    // elementChat.style.right = "unset";
    elementChat.style.animation = "unset";
  }
  elementRoom.style.transition = "all 0.3s linear";
  elementChat.style.transition = "all 0.3s linear";
};

export const formatDate = (date) => {
  if (_.isEmpty(date)) return;
  return moment(date).format("YYYY-MM-DD");
};
