import { formatDate } from "share/function";
import { UserStatus } from "share/helperCommon";

export const columnsRegisterUserData = (handleActived) => [
  {
    title: "Username",
    dataIndex: "userName",
  },
  {
    title: "Nickname",
    dataIndex: "nickName",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
  },
  {
    title: "Email",
    dataIndex: "mail",
  },
  {
    title: "Registration date",
    dataIndex: "createAt",
    render: (createAt) => `${formatDate(createAt)}`,
  },
  {
    title: "Result Setting",
    dataIndex: "setting",
    align: 'center',
    render: (text, record) => (
      <div className="grid grid-cols-2 gap-[7px] w-full">
        <button
          className="rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] lg:text-[9px] 2xl:text-[11px] p-[7px] text-nowrap box-border flex items-center justify-center"
          onClick={() => handleActived(record, true)}
        >
          승인
        </button>
        <button
          className="rounded-[20px] border-none outline-none bg-[#C83737] text-[#fff] lg:text-[9px] 2xl:text-[11px] p-[7px] text-nowrap box-border flex items-center justify-center"
          onClick={() => handleActived(record, false)}
        >
          거절
        </button>
      </div>
    ),
  },
];
