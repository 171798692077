import React from 'react'

const Fold = ({handleFold}) => {
  return (
    <button
        type="button"
        onClick={handleFold}
        className="cursor-pointer lg:w-[70px] lg:p-[7px_5px] 2xl:w-[100px] 2xl:p-[12px_10px] rounded-[15px] border-none outline-none bg-[#FFDF00]  flex items-center justify-center lg:text-[12px] 2xl:text-[16px] font-bold"
      >
        FOLD
      </button>
  )
}

export default Fold