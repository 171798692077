import { formatDate } from "share/function";
import { UserStatus } from "share/helperCommon";

export const columnsUserData = (handleEditUser, handleDeleteUser, handleViewDetail, handleInActivatedUser) => [
  {
    title: "Username",
    dataIndex: "userName",
  },
  {
    title: "Nickname",
    dataIndex: "nickName",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
  },
  {
    title: "Email",
    dataIndex: "mail",
  },
  {
    title: "Active",
    dataIndex: "status",
    render: (status) => {
      // switch(status) {
      //   case UserStatus.CREATED: {
      //     return ''
      //   }
      // }
      if (status == UserStatus.ACTIVATED) {
        return "활성화";
      }
      return "새로 생성된";
    },
  },
  {
    title: "Registration date",
    dataIndex: "createAt",
    render: (createAt) => `${formatDate(createAt)}`,
  },
  {
    title: "Setting",
    dataIndex: "setting",
    render: (text, record) => (
      <div className="grid grid-cols-3 gap-[7px] w-full">
        <button className="rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] lg:text-[9px] 2xl:text-[11px] p-[7px] text-nowrap box-border flex items-center justify-center" onClick={()=> handleEditUser(record)}>
          수정
        </button>
        {
          record?.status != 'blocked'
          &&

        <button className="rounded-[20px] border-none outline-none bg-[#FFDF00] text-[#000] lg:text-[9px] 2xl:text-[11px] p-[7px] text-nowrap box-border flex items-center justify-center" onClick={()=> handleInActivatedUser(record)}>
          비활성화
        </button>
        }
        <button className="rounded-[20px] border-none outline-none bg-[#C83737] text-[#fff] lg:text-[9px] 2xl:text-[11px] p-[7px] text-nowrap box-border flex items-center justify-center" onClick={()=> handleDeleteUser(record)}>
          삭제
        </button>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "log",
    align: "center",
    render: (text, record) => (
      <div className="flex items-center justify-center">
        <button className="rounded-[20px] min-w-[50px] border-none outline-none bg-[#A46928] text-[#fff] lg:text-[9px] 2xl:text-[11px] p-[7px] text-nowrap box-border flex items-center justify-center" onClick={()=> {handleViewDetail(record)}}>
          보기
        </button>
      </div>
    ),
  },
];
