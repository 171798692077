import { Popover, Slider } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { updateMyTurn } from "redux/action/roomAction";
import { ratioRaise as ratioRaiseList } from "share/helperCommon";

const Content = ({
  method,
  setRatioRaise,
  ratioRaise,
  handleRaise,
  handleAllIn,
}) => {
  const { control, watch, handleSubmit, setValue } = method;

  const { totalBet, mySeat, bet } = useSelector((state) => state.room);

  const handleFormData = (data) => {
    if (data.totalRaise) {
      if (
        mySeat.Player.PriceOnTable <
        (parseFloat(data.totalRaise) + (bet.TotalBet - bet.CurrentBet) ??
          2 * totalBet)
      ) {
        toast.error(
          `You don't have enough money to raise with ${
            parseFloat(data.totalRaise) + (bet.TotalBet - bet.CurrentBet) ??
            2 * totalBet
          }`
        );
      } else {
        let totalRaiseRS =
          (parseFloat(data.totalRaise) + (bet.TotalBet - bet.CurrentBet) ??
            2 * totalBet) -
            (bet.TotalBet - bet.CurrentBet) ?? 2 * totalBet;
        handleRaise(totalRaiseRS);
      }
    } else {
      let money =
        2 * totalBet + (bet.TotalBet - bet.CurrentBet) >
        mySeat?.Player.PriceOnTable
          ? mySeat.Player.PriceOnTable - (bet.TotalBet - bet.CurrentBet)
          : 2 * totalBet - (bet.TotalBet - bet.CurrentBet);
      if (money > 0) {
        handleRaise(money);
      } else {
        toast.error(
          `You don't have enough money to raise with ${
            parseFloat(data.totalRaise) + (bet.TotalBet - bet.CurrentBet) ??
            2 * totalBet
          }`
        );
      }
    }
  };

  const allIn = () => {
    setValue("totalRaise", mySeat.Player.PriceOnTable);
    handleAllIn();
  };

  const renderRatioRaise = () => {
    return ratioRaiseList?.map((ratio, index) => {
      return (
        <>
          <input
            name="ratioRaise"
            type="radio"
            id={`ratioRaise${ratio.value}`}
            className="hidden"
            value={ratio.value}
            onChange={(e) => {
              if (
                mySeat.Player.PriceOnTable - (bet.TotalBet - bet.CurrentBet) <
                totalBet * ratio.value
              ) {
                toast.error(
                  `You don't have enough money to raise with ${ratio.value}X`
                );
                setValue(
                  "totalRaise",
                  mySeat.Player.PriceOnTable - (bet.TotalBet - bet.CurrentBet)
                );
              } else {
                setValue("ratioRaise", ratio.value);
                setRatioRaise(ratio.value);
                setValue("totalRaise", totalBet * ratio.value);
              }
            }}
          />
          <label htmlFor={`ratioRaise${ratio.value}`}>
            <div
              className="cursor-pointer p-[5px_10px] bg-[#017848] text-[#fff] h-fit flex items-center justify-center rounded-[10px] border-[1px] border-solid border-[#FFDF00]"
              style={{
                background: `${
                  ratioRaise == ratio.value ? "#FFDF00" : "#017848"
                }`,
              }}
            >
              <span className="text-[15px]">{ratio.label}</span>
            </div>
          </label>
        </>
      );
    });
  };

  return (
    <form
      className="w-[180px] h-[280px] bg-no-repeat lg:bg-contain 2xl:bg-contain lg:text-[30px] 2xl:text-[40px] bg-[#13563B] rounded-[10px] border-[2px] border-solid border-[#FFDF00] flex flex-col justify-between p-[5px]"
      onSubmit={handleSubmit(handleFormData)}
    >
      <button
        type="button"
        className="rounded-[10px] border-none  p-[10px] bg-[#C83737] text-[#fff] font-bold"
        onClick={allIn}
      >
        ALL-IN
      </button>
      <div className="grid grid-cols-2 gap-[10px] h-[160px]">
        <div className="grid grid-cols-1 grid-rows-4 gap-[5px]">
          {renderRatioRaise()}
        </div>
        <div className="flex justify-center items-center">
          <Slider
            vertical
            defaultValue={2 * totalBet}
            value={watch("totalRaise")}
            tooltip={{ open: false }}
            className="h-[90%]"
            min={1}
            max={mySeat.Player?.PriceOnTable - (bet.TotalBet - bet.CurrentBet)}
            onChange={(newValue) => {
              setValue("totalRaise", newValue);
            }}
          />
        </div>
      </div>
      <div className="rounded-[10px] bg-[#fff] p-[5px] flex justify-between">
        <img src="/images/livegame.png" className="w-[30px] h-[30px]" />
        <Controller
          name="totalRaise"
          control={control}
          render={({ field }) => {
            return (
              <>
                <input
                  defaultValue={
                    2 * totalBet + (bet.TotalBet - bet.CurrentBet) >
                    mySeat?.Player.PriceOnTable
                      ? mySeat.Player.PriceOnTable -
                        (bet.TotalBet - bet.CurrentBet)
                      : 2 * totalBet - (bet.TotalBet - bet.CurrentBet)
                  }
                  max={mySeat.Player?.PriceOnTable}
                  min={1}
                  {...field}
                  className="w-[calc(100%_-_40px)] border-none outline-none"
                />
              </>
            );
          }}
        />
      </div>
      <div className="grid w-full">
        <button className="rounded-[10px] border-none  p-[10px] bg-[#FFDF00] text-[#000] font-bold">
          BET
        </button>
      </div>
    </form>
  );
};

const Raise = ({ handleRaise, handleAllIn }) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [ratioRaise, setRatioRaise] = useState(2);
  const method = useForm({
    mode: "onSubmit",
    defaultValues: {},
  });

  const handleClick = () => {
    setIsConfirm((prev) => !prev);
    if (isConfirm) {
      console.log("Confirm raise");
    }
  };

  return (
    <Popover
      content={
        <Content
          method={method}
          ratioRaise={ratioRaise}
          setRatioRaise={setRatioRaise}
          handleRaise={handleRaise}
          handleAllIn={handleAllIn}
        />
      }
      trigger="click"
    >
      {
        <button
          type="button"
          onClick={handleClick}
          className="cursor-pointer lg:w-[70px] lg:p-[7px_5px] 2xl:w-[100px] 2xl:p-[12px_10px] rounded-[15px] border-none outline-none bg-[#FFDF00]  flex items-center justify-center lg:text-[12px] 2xl:text-[16px] font-bold"
        >
          RAISE
        </button>
      }
    </Popover>
  );
};

export default Raise;
