import { Input, Select } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CustomPopup } from "share/components";
import { POPUP_ID } from "share/constantShared";
import { useUpdateUser } from "../../hook/useUpdateUser";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { closePopup, openPopup } from "redux/action/custom-popup/customPopupAction";
import { useSignup } from "main/user/signup/hook/useSignup";
import { popupIdSignUp } from "main/user/signup/constant";
import { UserStatus } from "share/helperCommon";

const UserDetail = ({
  data,
  fetchUser,
  isEdit = true,
  fetch,
  isAdd = false,
  isChooseUser = true,
}) => {
  const { mutate, isLoading } = useUpdateUser();
  const {mutate: createUser, isLoading: isLoadingCreateUser} = useSignup();
  const dispatch = useDispatch();

  const optionStatus = [
    {
      label: "Activated",
      value: "activated",
    },
    {
      label: "Created",
      value: "created",
    },
    {
      label: "Disabled",
      value: "disabled",
    },
    {
      label: "Deleted",
      value: "deleted",
    },
    {
      label: "Blocked",
      value: "blocked",
    },
    {
      label: "Hidden",
      value: "hidden",
    },
  ];

  const optionRole = [
    {
      label: "Client",
      value: 1,
    },
    {
      label: "Admin",
      value: 2,
    },
  ];

  const defaultValues = {
    userName: "",
    nickName: "",
    email: "",
    phone: "",
    status: "",
    accountBalance: 0,
    role: 0,
    ...(isAdd && {password: ''}),
    ...(isAdd && {cfPassword: ''})
  }

  const method = useForm({
    mode: "onChange",
    defaultValues,
  });

  const { handleSubmit, reset, control, setValue, watch } = method;

  useEffect(() => {
    if (!_.isEmpty(data) && !isAdd) {
      reset(data);
    } 
    if(isAdd) {
      reset(defaultValues);
      setValue("role", isChooseUser ? 1 : 2);
      setValue("status", UserStatus.CREATED);
    }
  }, [data, isChooseUser, isAdd]);

  const handleSubmitForm = async (data) => {
    if(isAdd) {
      const dataSubmit = {
        ...data,
        accountBalance: parseFloat(data?.accountBalance) ?? 1000,
        phone: data.phone && data.phone?.toString(),
      };
      await createUser({
        ...dataSubmit,
        onSuccess: async (msg) => {
          toast.success("Create successfull");
          localStorage.setItem("uidRegister", msg.data.uid);
          if(data?.role == 1) {
            dispatch(openPopup({ id: popupIdSignUp.confirmEmail }));
          }
          fetch();
        },
        onError: (msg) => {
          toast.error(msg.message);
        },
      });
    } else {
      await mutate({
        Data: { ...data, uid: parseInt(data?.uid) },
        onSuccess: async (res) => {
          toast.success("Update success!");
          await fetchUser();
          await fetch();
        },
        onError: (res) => {
          toast.error("Fail");
        },
      });
    }
  };

  const handleClose = () => {
    dispatch(closePopup({ id: POPUP_ID.USER_MGT_DETAIL }));
  };

  useLoadingEffect(isLoading || isLoadingCreateUser);

  return (
    <CustomPopup
      id={POPUP_ID.USER_MGT_DETAIL}
      style={{ padding: 0 }}
      closeIcon={false}
      className="no-padding"
    >
      <FormProvider {...method}>
        <form
          className="rounded-[10px] lg:w-[500px] 2xl:w-[600px] lg:h-[75vh] 2xl:h-[65vh] overflow-hidden"
          onSubmit={handleSubmit(handleSubmitForm)}
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important",
          }}
        >
          <div className="flex items-center justify-between bg-[#ABABAB] p-[20px]">
            <h5 className="text-[20px]">User Management</h5>
            <span onClick={handleClose} className="text-[20px] cursor-pointer">
              &times;
            </span>
          </div>
          <div className="p-[10px_20px] lg:h-[82%] 2xl:h-[88%] flex flex-col justify-between box-border items-center">
            {/* <Tabs defaultActiveKey="1" items={itemsTab} className="detailTab" /> */}
            <div className="grid grid-cols-1 gap-[10px] bg-[white] w-full">
              <div className="grid grid-cols-2 gap-[10px]">
                <div className="flex flex-col items-start">
                  <h5 className="2xl:text-[13px] mb-[5px]">Username</h5>
                  <Controller
                    control={control}
                    name="userName"
                    render={({ field }) => {
                      return (
                        <Input
                          disabled={!isAdd}
                          {...field}
                          // className={`lg:p-[7px] 2xl:p-[10px] ${
                          //   errors["roomId"] ? "input-error" : ""
                          // }`}
                        />
                      );
                    }}
                  />
                </div>
                <div className="flex flex-col items-start">
                  <h5 className="2xl:text-[13px] mb-[5px]">Nickname</h5>
                  <Controller
                    control={control}
                    name="nickName"
                    render={({ field }) => {
                      return (
                        <Input
                          disabled={!isEdit}
                          {...field}
                          // className={`lg:p-[7px] 2xl:p-[10px] ${
                          //   errors["roomId"] ? "input-error" : ""
                          // }`}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              {isAdd && (
                <div className="grid grid-cols-2 gap-[10px]">
                  <div className="flex flex-col items-start">
                    <h5 className="2xl:text-[13px] mb-[5px]">Password</h5>
                    <Controller
                      control={control}
                      name="password"
                      render={({ field }) => {
                        return (
                          <Input
                            type="password"
                            {...field}
                            // className={`lg:p-[7px] 2xl:p-[10px] ${
                            //   errors["roomId"] ? "input-error" : ""
                            // }`}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <h5 className="2xl:text-[13px] mb-[5px]">Confirm Password</h5>
                    <Controller
                      control={control}
                      name="cfPassword"
                      render={({ field }) => {
                        return (
                          <Input
                            type="password"
                            {...field}
                            // className={`lg:p-[7px] 2xl:p-[10px] ${
                            //   errors["roomId"] ? "input-error" : ""
                            // }`}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="grid grid-cols-2 gap-[10px]">
                <div className="flex flex-col items-start">
                  <h5 className="2xl:text-[13px] mb-[5px]">Email</h5>
                  <Controller
                    control={control}
                    name="mail"
                    render={({ field }) => {
                      return (
                        <Input
                          disabled={!isEdit}
                          {...field}
                          // className={`lg:p-[7px] 2xl:p-[10px] ${
                          //   errors["roomId"] ? "input-error" : ""
                          // }`}
                        />
                      );
                    }}
                  />
                </div>
                <div className="flex flex-col items-start">
                  <h5 className="2xl:text-[13px] mb-[5px]">Phone</h5>
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field }) => {
                      return (
                        <Input
                          disabled={!isEdit}
                          {...field}
                          // className={`lg:p-[7px] 2xl:p-[10px] ${
                          //   errors["roomId"] ? "input-error" : ""
                          // }`}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-[10px]">
                <div className="flex flex-col items-start">
                  <h5 className="2xl:text-[13px] mb-[5px]">Account Balance</h5>
                  <Controller
                    control={control}
                    name="accountBalance"
                    render={({ field }) => {
                      return (
                        <Input
                          disabled={!isEdit}
                          {...field}
                          // className={`lg:p-[7px] 2xl:p-[10px] ${
                          //   errors["roomId"] ? "input-error" : ""
                          // }`}
                        />
                      );
                    }}
                  />
                </div>
                <div className="flex flex-col items-start">
                  <h5 className="2xl:text-[13px] mb-[5px]">Status</h5>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field }) => {
                      return (
                        <Select
                          options={optionStatus}
                          disabled={!isEdit || isAdd}
                          {...field}
                          className="no-padding w-full"
                        />
                      );
                    }}
                  />
                </div>
                <div className="flex flex-col items-start">
                  <h5 className="2xl:text-[13px] mb-[5px]">Role</h5>
                  <Controller
                    control={control}
                    name="role"
                    render={({ field }) => {
                      return (
                        <Select
                          disabled
                          options={optionRole}
                          {...field}
                          className="no-padding w-full"
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            {isEdit && (
              <div className="m-[20px_0px_10px_0px] flex justify-end">
                <button
                  type="submit"
                  disabled={!isEdit}
                  className="bg-[#777777] text-[#fff] rounded-[30px] outline-none border-none lg:w-[130px] 2xl:w-[150px] lg:p-[10px_7px] 2xl:p-[15px_10px]"
                >
                  생성
                </button>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </CustomPopup>
  );
};

export default UserDetail;
