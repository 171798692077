import React, { useContext, useEffect } from "react";
import { CustomPopup } from "share/components";
import { homePopupID } from "../../constant";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Avatar, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useUpdateUser } from "main/admin/userMgt/hook/useUpdateUser";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import toast from "react-hot-toast";
import { IsMobileContext } from "fuse/layout/layout-master";
import {
  closePopup,
  openPopup,
} from "redux/action/custom-popup/customPopupAction";
import { UserStatus } from "share/helperCommon";
import { useGetOTP } from "main/user/signup/hook/useGetOTP";

const ProfileSetting = () => {
  const dispatch = useDispatch();

  const { fetchUser } = useContext(IsMobileContext);

  const { userInfo } = useSelector((state) => state.home);
  const {mutate: resendOTP, isLoading: isLoadingResendOTP} = useGetOTP();
  const { mutate, isLoading } = useUpdateUser();

  const defaultValues = {
    userName: "",
    nickName: "",
    phone: "",
    mail: "",
  };

  const method = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        nickName: yup.string().required(),
        phone: yup.string().required(),
        mail: yup.string().email().required(),
      })
    ),
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = method;

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      reset(userInfo);
    }
  }, [userInfo]);

  const changeProfile = async (data) => {
    await mutate({
      Data: {
        uid: parseInt(data.uid),
        userName: data.userName,
        nickName: data.nickName,
        phone: parseInt(data.phone),
        mail: data.mail,
      },
      onSuccess: async (res) => {
        await fetchUser();
        toast.dismiss();
        toast.success("Update profile success");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error("Update profile fail");
      },
    });
  };

  const changePassword = () => {
    dispatch(openPopup({ id: homePopupID.changePassword }));
  };

  const verifyAccount = async () => {
    const dataForm = {
      otpIdUser: parseInt(userInfo.uid),
      otpValidTime: new Date(),
    };
    await resendOTP({
      ...dataForm,
      onSuccess: (res) => {
        dispatch(closePopup({id: homePopupID.profileSetting}))
        toast.dismiss();
        toast.success("Send OTP successfull. Please check in your email");
        dispatch(openPopup({ id: homePopupID.verifyAccount }));
      },
      onError: (err) => {
        toast.dismiss();
        toast.error("Resend fail");
      },
    });
  };

  useLoadingEffect(isLoading || isLoadingResendOTP);

  return (
    <CustomPopup
      className="no-padding"
      title={false}
      id={homePopupID.profileSetting}
    >
      <FormProvider {...method}>
        <form
          onSubmit={handleSubmit(changeProfile)}
          className="rounded-[20px] border-solid border-[2px] border-[#FFDF00] p-[10px] lg:p-[20px] relative w-[85vw] lg:w-[350px] 2xl:w-[400px] flex flex-col items-center box-border"
        >
          <div
            className="absolute top-[-5px] left-[50%] rounded-[50%]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <Avatar
              size={60}
              style={{ background: "#979797" }}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-[6] lg:size-[7] 2xl:size-9"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              }
            />
          </div>
          <div className="grid grid-cols-1 gap-[10px] w-[80%] my-[10px] lg:my-[20px]">
            <div className="flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffbb00"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 mr-[5px]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                />
              </svg>
              <span className="text-[#ffbb00] font-bold text-[12px] lg:text-[17px] 2xl:text-[20px]">
                {userInfo?.accountBalance?.toLocaleString()}
              </span>
            </div>
            {userInfo?.status == UserStatus.CREATED && (
              <div className="flex flex-col">
                <div
                  className="text-red-400 text-center font-bold cursor-pointer"
                  onClick={verifyAccount}
                >
                  ! <span>Verify account here</span>
                </div>
              </div>
            )}
            <div className="flex flex-col">
              <h5 className="text-[12px] lg:text-[13px] 2xl:text-[15px] mb-[5px]">
                Username
              </h5>
              <Controller
                name="userName"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      disabled
                      className={`lg:p-[5px] 2xl:p-[7px] ${
                        errors["userName"] ? "input-error" : ""
                      }`}
                    />
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-[12px] lg:text-[13px] 2xl:text-[15px] mb-[5px]">
                Nickname
              </h5>
              <Controller
                name="nickName"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      className={`lg:p-[5px] 2xl:p-[7px] ${
                        errors["nickName"] ? "input-error" : ""
                      }`}
                    />
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-[12px] lg:text-[13px] 2xl:text-[15px] mb-[5px]">
                Phone
              </h5>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      className={`lg:p-[5px] 2xl:p-[7px] ${
                        errors["phone"] ? "input-error" : ""
                      }`}
                    />
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-[12px] lg:text-[13px] 2xl:text-[15px] mb-[5px]">
                Mail
              </h5>
              <Controller
                name="mail"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      className={`lg:p-[5px] 2xl:p-[7px] ${
                        errors["mail"] ? "input-error" : ""
                      }`}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-[20px] w-full mt-[15px] lg:mt-[20px]">
            <button
              type="submit"
              className="bg-[#13563B] text-[#fff] rounded-[30px] outline-none border-none p-[10px] 2xl:p-[15px_10px]"
            >
              Change profile
            </button>
            <button
              type="button"
              onClick={changePassword}
              className="bg-[#13563B] text-[#fff] rounded-[30px] outline-none border-none p-[10px] 2xl:p-[15px_10px]"
            >
              Change password
            </button>
          </div>
        </form>
      </FormProvider>
    </CustomPopup>
  );
};

export default ProfileSetting;
