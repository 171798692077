import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSignup } from "./hook/useSignup";
import toast from "react-hot-toast";
import ConfirmEmail from "./components/ConfirmEmail";
import { openPopup } from "redux/action/custom-popup/customPopupAction";
import { popupIdSignUp } from "./constant";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate, isLoading } = useSignup();
  const method = useForm({
    mode: "onSubmit",
    defaultValues: {
      userName: "",
      password: "",
      cfPassword: "",
      phone: "",
      mail: "",
      role: 1,
      nickName: "",
      accountBalance: 0,
    },
    resolver: yupResolver(
      yup.object().shape({
        userName: yup
          .string()
          .required()
          .test((val) => val.length > 3)
          .typeError(
            "Only letters, numbers are allowed. Mininum 3 characters."
          ),
        password: yup
          .string()
          .required()
          .matches(
            /[0-9]/,
            "At least 6 character, including letters, numbers and special characters."
          )
          .matches(
            /[^a-zA-Z0-9]/,
            "At least 6 character, including letters, numbers and special characters."
          )
          .test((val) => val.length > 5)
          .typeError(
            "At least 6 character, including letters, numbers and special characters."
          ),
        cfPassword: yup
          .string()
          .required()
          .oneOf([yup.ref("password")], "Not match password"),
        phone: yup
          .number("Must be a number")
          .required()
          .typeError("Must be a number, mininum")
          .test((val) => val?.toString()?.length > 8),
        mail: yup.string().email().required(),
        nickName: yup.string().required(),
      })
    ),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = method;

  const handleSubmitForm = async (data) => {
    const dataSubmit = {
      ...data,
      accountBalance: 1000,
      phone: data.phone && data.phone?.toString(),
    };
    await mutate({
      ...dataSubmit,
      onSuccess: (msg) => {
        toast.success("Signup successfull");
        localStorage.setItem("uidRegister", msg.data.uid);
        dispatch(openPopup({ id: popupIdSignUp.confirmEmail }));
      },
      onError: (msg) => {
        toast.error(msg.message);
      },
    });
  };

  const handleCancel = () => {
    navigate(-1);
    // dispatch(openPopup({ id: popupIdSignUp.confirmEmail }));
  };

  useLoadingEffect(isLoading);

  console.log("errors", errors);

  return (
    <div>
      <div className="flex flex-col items-center justify-top w-full h-full">
        <img
          src="/images/livegame.png"
          className="h-fit w-[100px] lg:w-[180px] m-[20px_0px] lg:m-[30px_0px_50px_0px]"
        />
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div
              className="rounded-[10px] border-[2px] border-solid border-[#FFDF00] p-[10px_20px] lg:p-[20px_40px_40px_40px] flex flex-col items-center w-[80vw] lg:w-[60vw] 2xl:w-[40vw] h-fit"
              style={{ backgroundColor: "rgba(119,119,119,0.7)" }}
            >
              <h4
                className="text-[#FFDF00] text-[20px] lg:text-[30px] border-solid border-[#FFDF00] mb-[20px] font-normal"
                style={{ borderWidth: "0px 0px 2px 0px" }}
              >
                SIGN UP(등록)
              </h4>
              <div className="grid grid-cols-1 gap-[10px] lg:gap-[20px] 2xl:mt-[40px] w-full">
                <div className="grid grid-cols-2 gap-[10px] lg:gap-[20px] 2xl:gap-[40px] w-full">
                  <Controller
                    name="userName"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <Input
                          {...field}
                          placeholder="ID(아이디)"
                          className="lg:p-[10px] 2xl:p-[15px]"
                        />
                        <p className="text-[#fff] font-[500] text-[10px] lg:text-[13px] mt-[5px]">
                          {errors?.userName?.message}
                        </p>
                      </div>
                    )}
                  />
                  <Controller
                    name="nickName"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <Input
                          {...field}
                          placeholder="Nickname(닉네임)"
                          className="lg:p-[10px] 2xl:p-[15px]"
                        />
                        <p className="text-[#fff] font-[500] text-[10px] lg:text-[13px] mt-[5px]">
                          {errors?.nickName?.message}
                        </p>
                      </div>
                    )}
                  />
                  {/* <div className="h-[50px]">
                  <p className="text-[16px] text-[#fff]">{}</p>
                </div> */}
                </div>
                <div className="grid grid-cols-2 gap-[10px] lg:gap-[20px] 2xl:gap-[40px] w-full">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <Input.Password
                          {...field}
                          placeholder="PW(비밀번호)"
                          className="lg:p-[10px] 2xl:p-[15px]"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                        <p className="text-[#fff] font-[500] text-[10px] lg:text-[13px] mt-[5px]">
                          {errors?.password?.message}
                        </p>
                      </div>
                    )}
                  />
                  <Controller
                    name="cfPassword"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <Input.Password
                          {...field}
                          placeholder="Confirm PW(비밀번호 확인)"
                          className="lg:p-[10px] 2xl:p-[15px]"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                        <p className="text-[#fff] font-[500] text-[10px] lg:text-[13px] mt-[5px]">
                          {errors?.cfPassword?.message}
                        </p>
                      </div>
                    )}
                  />
                </div>
                <div className="grid grid-cols-2 gap-[10px] lg:gap-[20px] 2xl:gap-[40px] w-full">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <Input
                          {...field}
                          placeholder="Phone number(휴대번호)"
                          className="lg:p-[10px] 2xl:p-[15px]"
                        />
                        <p className="text-[#fff] font-[500] text-[10px] lg:text-[13px] mt-[5px]">
                          {errors?.phone?.message}
                        </p>
                      </div>
                    )}
                  />
                  <Controller
                    name="mail"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <Input
                          {...field}
                          type="email"
                          placeholder="Email(이메일)"
                          className="lg:p-[10px] 2xl:p-[15px]"
                        />
                        <p className="text-[#fff] font-[500] text-[10px] lg:text-[13px] mt-[5px]">
                          {errors?.email?.message}
                        </p>
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-[10px] lg:gap-[20px] 2xl:gap-[40px] mt-[15px] lg:mt-[20px] 2xl:mt-[40px]">
                <button
                  type="button"
                  className="cursor-pointer w-full lg:w-[150px] 2xl:w-[200px] p-[10px] lg:p-[15px_10px] rounded-[20px] border-none outline-none m-[0_auto] bg-[#C83737] text-[#fff] flex items-center justify-center"
                  onClick={handleCancel}
                >
                  Cancel (취소)
                </button>
                <button
                  type="submit"
                  className="cursor-pointer w-full lg:w-[150px] 2xl:w-[200px] p-[10px] lg:p-[15px_10px] rounded-[20px] border-none outline-none m-[0_auto] bg-[#FFDF00] flex items-center justify-center"
                >
                  Sign up(등록)
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <ConfirmEmail />
    </div>
  );
};

export default SignUp;
