import { Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const Call = ({ handleCall }) => {
  const { bet } = useSelector((state) => state.room);
  return (
    <Tooltip placement="top" title={`${bet?.TotalBet - bet?.CurrentBet}`}>
      <button
        type="button"
        onClick={handleCall}
        className="cursor-pointer lg:w-[70px] lg:p-[7px_5px] 2xl:w-[100px] 2xl:p-[12px_10px] rounded-[15px] border-none outline-none bg-[#FFDF00]  flex items-center justify-center lg:text-[12px] 2xl:text-[16px] font-bold"
      >
        CALL
      </button>
    </Tooltip>
  );
};

export default Call;
