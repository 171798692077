import { Table } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";

const MainTable = ({
  columns,
  rowSelect = false,
  data,
  rowKey,
  className,
  styles,
  pageSize,
  onRowDoubleClick = false,
}) => {
  const [pagination, setPagination] = useState({
    pageSize: pageSize ?? 10,
  });

  const dataSource = useMemo(() => {
    if (!_.isEmpty(data)) {
      return data?.map((item, index) => {
        return { ...item, key: index };
      });
    }
    return;
  }, [data]);

  const handleChangePagination = (pagination) => {
    setPagination({
      pageSize: pagination.pageSize
    })
  }
  
  return (
    <Table
      style={styles}
      className={className}
      rowSelection={{
        type: "checkbox",
      }}
      onRow={(record)=> {
        return {
          onDoubleClick: () => onRowDoubleClick && onRowDoubleClick(record)
        }
      }}
      pagination={{
        pageSize: pagination.pageSize,
      }}
      columns={columns}
      dataSource={dataSource}
      onChange={handleChangePagination}
    />
  );
};

export default MainTable;
