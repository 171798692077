import { Modal } from "antd";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomPopup } from "share/components";

const PopupCommonRooms = ({ id, handleSearch }) => {
  const { handleSubmit, control, watch } = useFormContext();

  const fakeDataStake = [
    {
      label: "$1 / $2",
      value: 1,
    },
    {
      label: "$3 / $4",
      value: 2,
    },
  ];

  const fakeDataStatus = [
    {
      label: "All",
      value: 99,
    },
    {
      label: "Ready",
      value: 1,
    },
    {
      label: "Playing",
      value: 2,
    },
    {
      label: "Pause",
      value: 3,
    },
    {
      label: "Offline",
      value: 0,
    },
  ];

  const renderStakes = () => {
    return fakeDataStake?.map((stake, index) => {
      return (
        <>
          <Controller
            name="roomPrice"
            control={control}
            render={({ field }) => {
              return (
                <>
                  <label
                    className={`text-[#ffffffc2] text-[11px] lg:text-[15px] 2xl:text-[18px] font-bold text-center cursor-pointer ${
                      parseInt(watch("roomPrice")) == stake.value
                        ? "commonPopActivated"
                        : ""
                    }`}
                    key={index}
                    htmlFor={`roomPrice${index}`}
                  >
                    {stake.label}
                  </label>
                  <input
                    {...field}
                    type="radio"
                    className="hidden"
                    value={stake.value}
                    id={`roomPrice${index}`}
                  />
                </>
              );
            }}
          />
        </>
      );
    });
  };

  const renderStatus = () => {
    return fakeDataStatus?.map((stake, index) => {
      return (
        // <div
        //   className="text-[#fff] text-[11px] lg:text-[15px] 2xl:text-[18px] text-center cursor-pointer"
        //   key={index}
        // >
        //   {stake.label}
        // </div>

        <>
          <Controller
            name="dealerStatus"
            control={control}
            render={({ field }) => {
              return (
                <>
                  <label
                    className={`text-[#ffffffc2] text-[11px] lg:text-[15px] 2xl:text-[18px] font-bold text-center cursor-pointer ${
                      parseInt(watch("dealerStatus")) == stake.value
                        ? "commonPopActivated"
                        : ""
                    }`}
                    key={index}
                    htmlFor={`dealerStatus${index}`}
                  >
                    {stake.label}
                  </label>
                  <input
                    {...field}
                    type="radio"
                    className="hidden"
                    value={stake.value}
                    id={`dealerStatus${index}`}
                  />
                </>
              );
            }}
          />
        </>
      );
    });
  };

  return (
    <CustomPopup id={id} className="no-padding">
      <form
        className="bg-[#13563B] rounded-[10px] w-[90vw] lg:w-[60vw] 2xl:w-[500px] p-[10px] lg:p-[20px] box-border"
        onSubmit={handleSubmit(handleSearch)}
      >
        <div className="grid grid-cols-2 gap-[10px] lg:gap-[20px]">
          <div className="">
            <h5 className="text-[#FFDF00] font-bold text-[14px] lg:text-[20px] 2xl:text-[25px] mb-[10px] lg:mb-[15px] text-center border-b-[2px] border-t-[0px] border-r-[0px] border-l-[0px] border-solid">
              Stakes
            </h5>
            <div className="common-price grid grid-cols-2 w-full gap-[10px]">
              {renderStakes()}
            </div>
          </div>
          <div className="">
            <h5 className="text-[#FFDF00] font-bold text-[14px] lg:text-[20px] 2xl:text-[25px] mb-[10px] lg:mb-[15px] text-center border-b-[2px] border-t-[0px] border-r-[0px] border-l-[0px] border-solid">
              Status
            </h5>
            <div className="common-price grid grid-cols-2 w-full gap-[10px]">
              {renderStatus()}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-[30px]">
          <button className="flex items-center justify-center bg-[#fff] rounded-[20px] lg:p-[7px_10px] border-none outline-none font-bold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
            Search
          </button>
        </div>
      </form>
    </CustomPopup>
  );
};

export default PopupCommonRooms;
