import { Modal } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closePopup, openPopup } from 'redux/action/custom-popup/customPopupAction';

const CustomPopup = (
  { id, children,
    className,
    style,
    onCallbackFn,
    onCancel,
    onOk,
    closeIcon,
    footer = null,
    width, ...rest }
) => {
  const { storePopup } = useSelector((state) => state.popup);


  const { isOpen, title, storeStyle, storeCloseIcon, storeClassName  } = storePopup?.find(store => store.id === id) || {}
  const dispatch = useDispatch();


  const handleCancel = () => {
    dispatch(closePopup({ id }))
    onCancel && onCancel();
  }

  const handleOk = () => {
    if (typeof (onOk) === 'function') {
      onOk()
    }
    handleCancel()
  }
  return (
    <>
      <Modal
        afterClose={onCallbackFn}
        className={storeClassName || className}
        closeIcon={storeCloseIcon || closeIcon}
        footer={footer}
        open={isOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        // style={storeStyle ||style}
        style={storeStyle ||style}
        title={title ? <h2>{title}</h2>: null}
        width={width}
        {...rest}
      >
        {children}
      </Modal>
    </>
  )
}

export default CustomPopup