import { Tabs } from "antd";
import React, { useState } from "react";
import RoomContent from "./components/RoomContent";
import RoomDetail from "./components/detail/RoomDetail";

const RoomMgt = () => {
  const [isOpenCreate, setOpenCreate] = useState(false);
  const [showSlice, onShowSlice] = useState({
    open: false,
    initData: null,
  });

  const items = [
    {
      key: "1",
      label: (
        <span className="text-[#b57e36] lg:text-[22px] 2xl:text-[35px] font-bold">$1/$2</span>
      ),
      children: (
        <RoomContent data={{ roomPrice: 1 }} onShowSlice={onShowSlice} open={isOpenCreate} setOpen={setOpenCreate}/>
      ),
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };

  const handleCreateRoom = () => {
    setOpenCreate((prev) => !prev);
  };

  return (
    <>
      {!showSlice.open ? (
        <div className="w-full h-full">
          <div className="absolute right-[20px] top-[90px]">
            <button
              onClick={handleCreateRoom}
              className="bg-[#FFDF00] rounded-[20px] lg:p-[7px_10px] 2xl:p-[10px_20px] border-none outline-none text-[#13563B] font-bold"
            >
              게임방 생성
            </button>
          </div>
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ) : (
        <RoomDetail
          data={showSlice.initData}
          showSlice={showSlice}
          onShowSlice={onShowSlice}
        />
      )}
    </>
  );
};

export default RoomMgt;
