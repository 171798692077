import { Avatar } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const Chat = ({ data }) => {
  const { userInfo } = useSelector((state) => state.home);
  return (
    <>
      <div
        className={`flex flex-col ${data?.Uid == userInfo?.uid ? "items-end" : 'items-start'}`}
      >
        <div
          className={`flex items-center mb-[7px] ${
            data?.Uid == userInfo?.uid ? "flex-row-reverse" : 'flex-row'
          }`}
        >
          <Avatar style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}>
            {data?.NickNamePlayer?.charAt(0)?.toUpperCase()}
          </Avatar>
          <span
            className={`${
              data?.Uid == userInfo?.uid ? "mr-[5px]" : "ml-[5px]"
            } font-[500] lg:text-[12px] 2xl:text-[14px]`}
          >
            {data?.NickNamePlayer}
          </span>
          {data?.SeatPosCode > -1 && (
            <div className="bg-[#B27939] rounded-[50%] w-[20px] h-[20px] flex items-center justify-center text-[white] text-[11px] ml-[10px] font-bold">
              s{data?.SeatPosCode}
            </div>
          )}
        </div>
        <div className="rounded-[10px] bg-[#13563B] lg:p-[7px] 2xl:p-[10px] h-fit w-fit flex items-center">
          <span className="text-[#fff] lg:text-[12px] leading-[13px] 2xl:text-[14px]">
            {data?.Message}
          </span>
        </div>
      </div>
    </>
  );
};

export default Chat;
