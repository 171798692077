import React, { useContext, useMemo, useState } from "react";
import useGetUserData from "./hook/useGetUserData";
import MainTable from "../shareComponent/table/MainTable";
import { columnsUserData } from "./helper";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import Filters from "./components/Filters";
import { FormProvider, useForm } from "react-hook-form";
import { useFilters } from "share/function";
import UserDetail from "./components/detail/UserDetail";
import { useDispatch } from "react-redux";
import {
  openPopup,
  closePopup,
} from "redux/action/custom-popup/customPopupAction";
import { POPUP_ID } from "share/constantShared";
import useGetUserById from "./hook/useGetUserById";
import { dialogConfirmId } from "./constant";
import DialogConfirm from "../shareComponent/dialog/DialogConfirm";
import { useUpdateUser } from "./hook/useUpdateUser";
import toast from "react-hot-toast";
import { useDeleteUser } from "./hook/useDeleteUser";
import { SocketAdminProvider } from "fuse/layout-admin/layout-master";
import _ from "lodash";
import ConfirmEmail from "main/user/signup/components/ConfirmEmail";

const UserManagement = () => {
  const [showSlice, onShowSlice] = useState({
    initData: null,
    isEdit: true,
    isAdd: false,
  });
  const [isChooseUser, setChooseUser] = useState(true);
  const { socket } = useContext(SocketAdminProvider);

  const defaultValues = {
    userName: "",
  };

  const method = useForm({
    mode: "onSubmit",
    defaultValues,
  });

  const { reset } = method;

  const { count, setCount, filters, onFilter } = useFilters(defaultValues);

  const {
    data: dataUser,
    isLoading,
    fetch,
    isFetching,
  } = useGetUserData(filters);

  const {
    data: dataUserDetail,
    isLoading: isLoadingDetail,
    isFetching: isFetchingDetail,
    fetch: fetchDetail,
  } = useGetUserById(showSlice?.initData?.uid);

  const dataUserChoose = useMemo(() => {
    if (!_.isEmpty(dataUser)) {
      return dataUser?.filter((user) => user.roleId == (isChooseUser ? 1 : 2));
    }
    return [];
  }, [dataUser, isChooseUser]);

  const { mutate: deleteUser, isLoading: isLoadingDelete } = useDeleteUser();
  const { mutate: updateUser, isLoading: isLoadingUpdate } = useUpdateUser();

  const dispatch = useDispatch();

  // let test = [
  //   {
  //     user_uid: 1,
  //     user_phone: "123456789",
  //     userName: "test",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Bụng bự",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 2,
  //     user_phone: "123456789",
  //     userName: "test2",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Tóc xoăn",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 3,
  //     user_phone: "123456780",
  //     userName: "test3",
  //     mail: "truongthien241@gmail.com",
  //     nickName: "Quyên",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 4,
  //     user_phone: "3432424234",
  //     userName: "test4",
  //     mail: "test4@gmail.com",
  //     nickName: "Vỹ 100 tỉ",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 5,
  //     user_phone: "1234566777",
  //     userName: "admin",
  //     mail: "admin@gmail.com",
  //     nickName: "Admin",
  //     status: "activated",
  //     roleId: 2,
  //     roleName: "admin",
  //   },
  //   {
  //     user_uid: 6,
  //     user_phone: "123456780",
  //     userName: "livegame",
  //     mail: "livetest@gmail.com",
  //     nickName: "Live Game",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 7,
  //     user_phone: "123456789",
  //     userName: "tinnt",
  //     mail: "tinnt@gmail.com",
  //     nickName: "Thích cà khịa",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 8,
  //     user_phone: "123456789",
  //     userName: "phongnt",
  //     mail: "truongthien24113@gmail.com",
  //     nickName: "Phong Đại Za",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 10,
  //     user_phone: null,
  //     userName: "kangdirector",
  //     mail: "kangdirector@gmail.com",
  //     nickName: "Kang Director",
  //     status: "created",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 11,
  //     user_phone: null,
  //     userName: "kimdirector",
  //     mail: "kimdirector@gmail.com",
  //     nickName: "Kim Director",
  //     status: "created",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 12,
  //     user_phone: "123456789",
  //     userName: "quyen",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "올인",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 13,
  //     user_phone: "123456789",
  //     userName: "khoa",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Khoa",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 14,
  //     user_phone: "123456789",
  //     userName: "vy",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Vỹ 100 tỉ",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 15,
  //     user_phone: "123456789",
  //     userName: "tinnt1",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Tín nè",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 16,
  //     user_phone: "123456789",
  //     userName: "dealer",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Dealer",
  //     status: "activated",
  //     roleId: 4,
  //     roleName: "dealer",
  //   },
  //   {
  //     user_uid: 1,
  //     user_phone: "123456789",
  //     userName: "test",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Bụng bự",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 2,
  //     user_phone: "123456789",
  //     userName: "test2",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Tóc xoăn",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 3,
  //     user_phone: "123456780",
  //     userName: "test3",
  //     mail: "truongthien241@gmail.com",
  //     nickName: "Quyên",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 4,
  //     user_phone: "3432424234",
  //     userName: "test4",
  //     mail: "test4@gmail.com",
  //     nickName: "Vỹ 100 tỉ",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 5,
  //     user_phone: "1234566777",
  //     userName: "admin",
  //     mail: "admin@gmail.com",
  //     nickName: "Admin",
  //     status: "activated",
  //     roleId: 2,
  //     roleName: "admin",
  //   },
  //   {
  //     user_uid: 6,
  //     user_phone: "123456780",
  //     userName: "livegame",
  //     mail: "livetest@gmail.com",
  //     nickName: "Live Game",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 7,
  //     user_phone: "123456789",
  //     userName: "tinnt",
  //     mail: "tinnt@gmail.com",
  //     nickName: "Thích cà khịa",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 8,
  //     user_phone: "123456789",
  //     userName: "phongnt",
  //     mail: "truongthien24113@gmail.com",
  //     nickName: "Phong Đại Za",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 10,
  //     user_phone: null,
  //     userName: "kangdirector",
  //     mail: "kangdirector@gmail.com",
  //     nickName: "Kang Director",
  //     status: "created",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 11,
  //     user_phone: null,
  //     userName: "kimdirector",
  //     mail: "kimdirector@gmail.com",
  //     nickName: "Kim Director",
  //     status: "created",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 12,
  //     user_phone: "123456789",
  //     userName: "quyen",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "올인",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 13,
  //     user_phone: "123456789",
  //     userName: "khoa",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Khoa",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 14,
  //     user_phone: "123456789",
  //     userName: "vy",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Vỹ 100 tỉ",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 15,
  //     user_phone: "123456789",
  //     userName: "tinnt1",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Tín nè",
  //     status: "activated",
  //     roleId: 1,
  //     roleName: "client",
  //   },
  //   {
  //     user_uid: 16,
  //     user_phone: "123456789",
  //     userName: "dealer",
  //     mail: "truongthien2411@gmail.com",
  //     nickName: "Dealer",
  //     status: "activated",
  //     roleId: 4,
  //     roleName: "dealer",
  //   },
  // ];

  const handleChooseTypeUser = (type) => {
    setChooseUser(type == "user");
  };

  const handleSearch = (data) => {
    onFilter({ userName: data.userName });
  };

  const handleEditUser = (data) => {
    onShowSlice({ initData: data, isEdit: true, isAdd: false });
    dispatch(openPopup({ id: POPUP_ID.USER_MGT_DETAIL }));
  };

  const handleDeleteUser = (data) => {
    onShowSlice({ initData: data });
    dispatch(openPopup({ id: dialogConfirmId.deleteUser }));
  };

  const handleInActivatedUser = (data) => {
    onShowSlice({ initData: data });
    dispatch(openPopup({ id: dialogConfirmId.inActivatedUser }));
  };

  const handleRefresh = async () => {
    reset();
    onFilter({});
    await fetch();
  };

  const handleAdd = async () => {
    onShowSlice({ initData: false, isAdd: true, isEdit: true });
    dispatch(openPopup({ id: POPUP_ID.USER_MGT_DETAIL }));
  };

  const handleViewDetail = (data) => {
    onShowSlice({ initData: data, isEdit: false, isAdd: false });
    dispatch(openPopup({ id: POPUP_ID.USER_MGT_DETAIL }));
  };

  const confirmDeleteUser = async () => {
    const { uid } = showSlice.initData;
    await deleteUser({
      Data: {
        uid,
      },
      onSuccess: async (res) => {
        dispatch(closePopup({ id: dialogConfirmId.deleteUser }));
        toast.success("Delete successful");
        onShowSlice({ initData: null, isEdit: true });
        await fetch();
      },
      onError: (err) => {
        toast.error("Delete error");
      },
    });
  };

  const confirmInActivatedUser = async () => {
    const { uid } = showSlice.initData;
    await updateUser({
      Data: {
        uid,
        status: "blocked",
      },
      onSuccess: async (res) => {
        dispatch(closePopup({ id: dialogConfirmId.inActivatedUser }));
        toast.success("Inactivated successful");
        await fetch();
        socket.send(
          JSON.stringify({
            FunctionCode: "block_user",
            Data: showSlice.initData,
          })
        );
        onShowSlice({ initData: null, isEdit: true });
      },
      onError: (err) => {
        toast.error("Inactivated error");
      },
    });
  };

  useLoadingEffect(isLoading || isLoadingDelete);

  return (
    <div className="h-full w-full">
      <div className="flex items-center justify-center p-[5px]">
        <button
          className={`p-[10px] w-[100px] rounded-[20px] border-none bg-none outline-none ${
            isChooseUser ? "bg-[#A46928]" : "bg-[#c3945f]"
          } text-[#fff] font-bold mr-[5px]`}
          onClick={() => handleChooseTypeUser("user")}
        >
          User
        </button>
        <button
          className={`p-[10px] w-[100px] rounded-[20px] border-none bg-none outline-none ${
            !isChooseUser ? "bg-[#A46928]" : "bg-[#c3945f]"
          } text-[#fff] font-bold`}
          onClick={() => handleChooseTypeUser("admin")}
        >
          Admin
        </button>
      </div>
      <FormProvider {...method}>
        <Filters
          onSubmit={handleSearch}
          onRefresh={handleRefresh}
          onAdd={handleAdd}
        />
      </FormProvider>
      <div className="h-[calc(100%_-_125px)] w-full p-[10px_0px]">
        <MainTable
          columns={columnsUserData(
            handleEditUser,
            handleDeleteUser,
            handleViewDetail,
            handleInActivatedUser
          )}
          data={dataUserChoose}
          rowSelect={true}
          rowKey="uid"
          pageSize={20}
          onRowDoubleClick={(row) => {
            onShowSlice({ initData: row, isEdit: false });
            dispatch(openPopup({ id: POPUP_ID.USER_MGT_DETAIL }));
          }}
        />
      </div>
      <UserDetail
        data={dataUserDetail}
        fetchUser={fetchDetail}
        fetch={fetch}
        isEdit={showSlice.isEdit}
        isAdd={showSlice.isAdd}
        isChooseUser={isChooseUser}
      />
      <DialogConfirm
        id={dialogConfirmId.deleteUser}
        render={
          <div className="flex flex-col justify-between p-[10px_20px] 2xl:min-w-[200px] 2xl:min-h-[100px]">
            <div className="flex justify-between dialog-header">
              <h5 className="lg:text-[20px] 2xl:text-[23px]">Confirm</h5>
              {/* <button className="outline-none border-none bg-transparent">&times;</button> */}
            </div>
            <div className="dialog-body my-[30px]">
              <p className="lg:text-[13px] 2xl:text-[17px]">
                Are you sure to delete user?
              </p>
            </div>
            <div className="dialog-footer flex items-center justify-end">
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px_10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#C83737] text-[#fff] flex items-center justify-center"
                onClick={() => {
                  onShowSlice({ initData: null, isEdit: true });
                  dispatch(closePopup({ id: dialogConfirmId.deleteUser }));
                }}
              >
                Cancel
              </button>
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px_10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] flex items-center justify-center ml-[10px]"
                onClick={confirmDeleteUser}
              >
                OK
              </button>
            </div>
          </div>
        }
      />

      <DialogConfirm
        id={dialogConfirmId.inActivatedUser}
        render={
          <div className="flex flex-col justify-between p-[10px_20px] 2xl:min-w-[200px] 2xl:min-h-[100px]">
            <div className="flex justify-between dialog-header">
              <h5 className="lg:text-[20px] 2xl:text-[23px]">Confirm</h5>
              {/* <button className="outline-none border-none bg-transparent">&times;</button> */}
            </div>
            <div className="dialog-body my-[30px]">
              <p className="lg:text-[13px] 2xl:text-[17px]">
                Are you sure to Inactivated user?
              </p>
            </div>
            <div className="dialog-footer flex items-center justify-end">
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#C83737] text-[#fff] flex items-center justify-center"
                onClick={() => {
                  onShowSlice({ initData: null, isEdit: true });
                  dispatch(closePopup({ id: dialogConfirmId.inActivatedUser }));
                }}
              >
                Cancel
              </button>
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] flex items-center justify-center ml-[10px]"
                onClick={confirmInActivatedUser}
              >
                OK
              </button>
            </div>
          </div>
        }
      />

      <ConfirmEmail />
    </div>
  );
};

export default UserManagement;
