import { useMutation } from "react-query";
import axiosWrapper from "service/jwtService/jwtService";

const postData = async (data) => {
  return await axiosWrapper({
    url: `/users/update`,
    method: "put",
    data: data.Data,
  });
};

export const useUpdateUserInfo = () => {
  return useMutation(postData, {
    onSuccess: (_, { onSuccess, status }) => {
      onSuccess(_);
    },
    onError: (_, { onError, status }) => {
      onError(_);
    },
  });
};
