export const loginSuccess = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateUserInfo = (data) => (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_USER_INFO",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateRoom = (data) => (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_ROOM",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const changeDataRoomByID = (data) => (dispatch) => {
  try {
    dispatch({
      type: "CHANGE_DATA_ROOM_BY_ID",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};
