import React from "react";
import { Popover, Select, Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { POPUP_ID } from "share/constantShared";
import { openPopup } from "redux/action/custom-popup/customPopupAction";
import { updateVolume } from "redux/action/toolbarAction";

const SoundSetting = ({ data }) => {
  const {
    music: {
      StartGame,
      Main,
      Winner,
      Call,
      Check,
      CFold,
      Raise,
      Throwing,
      AllIn,
      VolumeMain,
      VolumeEffect,
    },
  } = useSelector((state) => state.toolbar);

  const dispatch = useDispatch();

  const handleChangeNickname = () => {
    dispatch(openPopup({ id: POPUP_ID.CHANGE_NICKNAME }));
  };

  const SoundContent = () => {
    return (
      <div className="p-[10px] bg-[#13563B] rounded-[6px] text-[white] grid grid-cols-2 lg:gap-[15px] 2xl:gap-[25px]">
        <div className="grid grid-cols-1 gap-[10px]">
          <h5 className="text-[11px] lg:text-[14px] 2xl:text-[18px] text-[#FFDF00]">
            Sound
          </h5>
          <div className="flex items-center justify-between">
            <p className="mr-[10px] font-[500] text-[10px] lg:text-[12px] 2xl:text-[15px]">
              Background
            </p>
            {/* <Switch
              defaultChecked
              onChange={(event) => {
                if (event) {
                  Main.play();
                } else {
                  Main.pause();
                }
              }}
            /> */}
            <Slider
              defaultValue={VolumeMain}
              // value={VolumeMain}
              tooltip={{ open: false }}
              className="lg:w-[60px] 2xl:w-[80px]"
              min={0}
              max={100}
              onChange={(newValue) => {
                Main.volume = newValue * 0.01;
              }}

              onChangeComplete={(newValue) => {
                dispatch(
                  updateVolume({
                    type: "main",
                    volume: newValue,
                  })
                );
              }}
            />
          </div>
          <div className="flex items-center justify-between">
            <p className="mr-[10px] font-[500] text-[10px] lg:text-[12px] 2xl:text-[15px]">
              Effect
            </p>
            {/* <Switch
              defaultChecked
              onChange={(event) => {
                if (event) {
                  Winner.volume = 0.5;
                  Call.volume = 0.5;
                  Check.volume = 0.5;
                  CFold.volume = 0.5;
                  Raise.volume = 0.5;
                  StartGame.volume = 0.5;
                  Throwing.volume = 0.5;
                  AllIn.volume = 0.5;
                } else {
                  Winner.volume = 0;
                  Call.volume = 0;
                  Check.volume = 0;
                  CFold.volume = 0;
                  Raise.volume = 0;
                  StartGame.volume = 0;
                  Throwing.volume = 0;
                  AllIn.volume = 0;
                }
              }}
            /> */}
            <Slider
              defaultValue={VolumeEffect}
              // value={VolumeEffect}
              tooltip={{ open: false }}
              className="lg:w-[60px] 2xl:w-[80px]"
              min={0}
              max={100}
              onChange={(newValue) => {
                Winner.volume = newValue * 0.01;
                Call.volume = newValue * 0.01;
                Check.volume = newValue * 0.01;
                CFold.volume = newValue * 0.01;
                Raise.volume = newValue * 0.01;
                StartGame.volume = newValue * 0.01;
                Throwing.volume = newValue * 0.01;
                AllIn.volume = newValue * 0.01;
              }}
              onChangeComplete={(newValue) => {
                dispatch(
                  updateVolume({
                    type: "effect",
                    volume: newValue,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-[10px]">
          <h5 className="text-[11 px] lg:text-[14px] 2xl:text-[18px] text-[#FFDF00]">
            Account
          </h5>
          <div className="flex items-center justify-between">
            <p className="mr-[10px] font-[500] text-[10px] lg:text-[12px] 2xl:text-[15px]">
              Language
            </p>
            <Select
              className="w-[80px] toolbarRoomSelect"
              defaultValue={"en"}
              options={[
                { label: "En", value: "en" },
                { label: "Kr", value: "ko" },
              ]}
            />
          </div>
          <div className="flex items-center justify-between">
            <p className="mr-[10px] font-[500] text-[10px] lg:text-[12px] 2xl:text-[15px]">
              Nickname
            </p>
            <button
              className="outline-none border-none bg-transparent"
              onClick={handleChangeNickname}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Popover
        content={<SoundContent />}
        // title="Sound setting"
        trigger="click"
        className="toolbarPopover"
      >
        <button
          className="bg-transparent outline-none border-none w-[15px] h-[15px] lg:w-[18px] lg:h-[18px] 2xl:w-[20px] 2xl:h-[20px] "
          // onClick={handleOnClick}
        >
          <img src={data.icon} className="w-full h-full object-cover" />
        </button>
      </Popover>
    </>
  );
};

export default SoundSetting;
