import React, { createContext, useEffect, useState } from "react";
import Navigation from "./navigation";
import { Outlet } from "react-router-dom";
import Navbar from "./navbar";
import * as io from "socket.io-client";

export const SocketAdminProvider = createContext(null);

const LayoutAdminMaster = () => {

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // const socketAPI = new WebSocket(
    //   "ws://192.168.100.66:3002/socket-api"
    // );

    const socketAPI = new WebSocket(
      "wss://api.clark.thlone.vn/socket-api/"
    );

    setSocket(socketAPI);

    socketAPI.onopen = () => {

      const auth = {
        token: localStorage.getItem("jwt") || null,
      };

      socketAPI.send(JSON.stringify({ FunctionCode: "authorization", Data: auth }));

      setInterval(() => {
        if (socketAPI.readyState === WebSocket.OPEN) {
          socketAPI.send(JSON.stringify({ FunctionCode: "ping", Data: {} }));
        }
      }, 30000);
    };
  }, []);

  return (
    <SocketAdminProvider.Provider value={{ socket: socket }}>
      <div className="flex items-center justify-center">
        <Navigation />
        <div className="lg:w-[calc(100vw_-_220px)] 2xl:w-[calc(100vw_-_256px)] bg-transparent h-screen box-border">
          <Navbar />
          <div className="lg:p-[15px] 2xl:p-[20px] h-[calc(100vh_-_50px)] box-border flex items-center justify-center">
            <Outlet />
          </div>
        </div>
      </div>
    </SocketAdminProvider.Provider>
  );
};

export default LayoutAdminMaster;
