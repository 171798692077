import { combineReducers } from "redux";
import { homeReducer } from "./homeReducer";
import { roomReducer } from "./roomReducer";
import { popupReducer } from "./popupReduce";
import { toolbarReducer } from "./toolbarReducer";

const reducers = combineReducers({
    home: homeReducer,
    room: roomReducer,
    popup: popupReducer,
    toolbar: toolbarReducer,
})
export default reducers;