import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import LayoutMaster from "./fuse/layout/layout-master";
import MainRouter from "./router/MainRouter";
import { useContext, useEffect } from "react";
import { SocketContext } from "context/socket";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { joinRoom, leaveSeat, takeSeat } from "redux/action/roomAction";
import "./util/scss/style.css";
import { w3cwebsocket } from "websocket";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // socket.on("connect", () => {});
  }, []);


  return (
    <QueryClientProvider client={queryClient}>
      <LayoutMaster component={<MainRouter />} />
    </QueryClientProvider>
  );
}

export default App;
