import React from "react";
import ToolbarItem from "./components/toolbarItem";
import { fakeDataToolbarRoom } from "../../constant";
import _ from "lodash";

const ToolbarRoom = ({ handleRefresh, handleChangeCameraStream }) => {
  const renderToolbar = () => {
    if (_.isEmpty(fakeDataToolbarRoom)) return;
    return fakeDataToolbarRoom?.map((toolbar, index) => {
      return (
        <ToolbarItem
          data={toolbar}
          key={index}
          handleRefresh={handleRefresh}
          handleChangeCameraStream={handleChangeCameraStream}
        />
      );
    });
  };
  return (
    <div
      className="grid grid-cols-6 gap-[15px] lg:gap-[30px] p-[7px_10px] lg:p-[8px_15px] 2xl:p-[10px_20px] rounded-[10px] border-[2px] border-solid border-[#FFDF00] box-border h-[40px] 2xl:h-[45px]"
      style={{ backgroundColor: "rgba(119,119,119,0.7)" }}
    >
      {renderToolbar()}
    </div>
  );
};

export default ToolbarRoom;
