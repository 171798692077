import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Chat from "./Chat";
import _ from "lodash";
import { Empty } from "antd";

const ShowChat = () => {
  const { chat } = useSelector((state) => state.room);

  useEffect(() => {
    const showChatWrap = document.querySelector("#showChatWrap");
    const newMessageNotify = document.querySelector("#newMessageNotify");
    const scrollTop = showChatWrap?.scrollTop;
    const clientHeight = showChatWrap?.clientHeight;
    const scrollHeight = showChatWrap?.scrollHeight;
    if (
        scrollHeight - clientHeight - scrollTop > 
        50
        // (scrollHeight - clientHeight) / 2
    //   clientHeight - scrollTop >
    //   50
    ) {
      newMessageNotify.style.display = "block";
    }
  }, [chat?.length]);

  useEffect(() => {
    const showChatWrap = document.querySelector("#showChatWrap");
    const newMessageNotify = document.querySelector("#newMessageNotify");
    showChatWrap.addEventListener("scroll", (e) => {
      const scrollTop = showChatWrap?.scrollTop;
      const clientHeight = showChatWrap?.clientHeight;
      const scrollHeight = showChatWrap?.scrollHeight;
      if (
        scrollHeight - clientHeight - scrollTop < 
        50
        // (scrollHeight - clientHeight) / 2
        // clientHeight - scrollTop <
        // 50
      ) {
        newMessageNotify.style.display = "none";
      }
    });
  }, []);

  const renderChat = () => {
    if (_.isEmpty(chat))
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No messages yet"
        />
      );
    return chat?.map((chat, index) => {
      return <Chat data={chat} key={index} />;
    });
  };

  const handleSeenNewMessage = () => {
    const showChatWrap = document.querySelector("#showChatWrap");
    showChatWrap.scrollTop = showChatWrap?.scrollHeight;
  };

  return (
    <div className="h-[calc(100%_-_100px)] flex flex-col justify-end items-start w-full overflow-hidden relative">
      <div
        className={`grid grid-cols-1 lg:gap-[10px] 2xl:gap-[13px] overflow-y-auto w-full max-h-full ${
          _.isEmpty(chat) ? "h-full flex items-center" : "h-fit"
        }`}
        id="showChatWrap"
      >
        {renderChat()}
        <div
          className="absolute w-fit bottom-[10px] right-[20px] bg-[#B27939] rounded-[10px] text-[white] flex items-center p-[5px_10px] cursor-pointer"
          id="newMessageNotify"
          style={{ display: "none", scrollBehavior: "smooth" }}
          onClick={handleSeenNewMessage}
        >
          New Message
          <img
            src="/images/icon9.png"
            className="lg:w-[13px] lg:h-[18px] 2xl:w-[20px] 2xl:h-[25px] down-arrow ml-[10px]"
          />
        </div>
      </div>
    </div>
  );
};

export default ShowChat;
