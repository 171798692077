import { Input } from "antd";
import FormControlFilter from "main/admin/shareComponent/filter/FormControlFilter";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";

const Filters = ({ onSubmit, onRefresh }) => {
  const { control, handleSubmit } = useFormContext();
  return (
    <form
      className="h-[80px] w-full bg-[#eaeaea] rounded-[10px] p-[10px] box-border grid grid-cols-5 gap-[10px] relative"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControlFilter
        buttons={["refresh", "search"]}
        onRefresh={onRefresh}
        onExcel={() => {
          toast.success("Chức năng đang phát triển");
        }}
      />
      <div className="flex flex-col items-start">
        <h5 className="mb-[5px] 2xl:text-[14px]">User ID:</h5>
        <Controller
          control={control}
          name="userId"
          render={({ field }) => {
            return <Input {...field} />;
          }}
        />
      </div>
    </form>
  );
};

export default Filters;
