import  i18n  from "i18next";
import { initReactI18next } from "react-i18next";
 
const language = localStorage.getItem('language');

const resources = {
    en: {
      translation: {
        "home0001": "Common rooms",
      },
    },
    kr: {
      translation: {
        "home0001": "공용실",
      },
    },
};

i18n.use(initReactI18next).init({
    resources,
    // lng: "en",
    lng: `${language}`,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
});

export default i18n;










