import { Input, Modal, Select, Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import toast from "react-hot-toast";
import { useFilters } from "share/function";
import { useNavigate } from "react-router-dom";
import useGetRoom from "../hook/useGetRoom";
import { useCreateRoom } from "../hook/useCreateRoom";
import GeneralTab from "./detail/GeneralTab";
import CameraTab from "./detail/CameraTab";
import ConfigTab from "./detail/ConfigTab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SocketAdminProvider } from "fuse/layout-admin/layout-master";

const FormCreateRoom = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { socket } = useContext(SocketAdminProvider);
  const { count, setCount, filters, onFilter } = useFilters({});
  const { mutate, isLoading } = useCreateRoom();
  const {
    data,
    isLoading: loadingRoom,
    isFetching,
    fetch,
  } = useGetRoom(filters);

  const method = useForm({
    mode: "onChange",
    defaultValues: {
      roomPrice: 1,
      roomName: "",
      roomStatus: 1,
      roomTime: 1,
      currency: "usd",
    },
    resolver: yupResolver(
      yup.object().shape({
        roomUrl: yup.string().required(),
        roomId: yup
          .string()
          .required()
          .test((rs) => rs.length < 255),
        // streamVideoUrl: yup.string().required(),
        roomTime: yup.number().required().min(1),
        // autoShowdown: yup.string().required(),
        showdownTime: yup.number().required(),
        numberPlayer: yup.number().required(),
        onRoomTimeOut: yup.number().required(),
        sitOutTime: yup.number().required(),
        minimumBuyIn: yup.number().required(),
        maximumBuyIn: yup.number().required(),
      })
    ),
  });

  const { control, getValues, reset, watch, handleSubmit, setValue } = method;

  useEffect(() => {
    if (data?.data) {
      setValue("roomName", `Room ${data?.data?.length + 1}`);
    }
  }, [data]);

  const handleCreateRoom = async (data) => {
    const dataSubmit = {
      ...data,
      roomId: data.roomId?.toString(),
      roomTime: parseInt(data.roomTime),
      decisionTime: parseInt(data.decisionTime),
      numberPlayer: parseInt(data.numberPlayer),
      dealerStatus: 0,
      seatedPlayers: 0,
      onRoomTimeOut: parseInt(data.onRoomTimeOut),
      autoShowdown: 1,
    };
    await mutate({
      ...dataSubmit,
      onSuccess: async (msg) => {
        toast.success("Create success");
        socket.send("create_room", msg.data);
        await fetch();
      },
      onError: (msg) => {
        toast.error(msg.message);
      },
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useLoadingEffect(isLoading);

  const itemsTab = [
    {
      key: "1",
      label: "General",
      children: <GeneralTab />,
    },
    {
      key: "2",
      label: "Camera",
      children: <CameraTab />,
    },
    {
      key: "3",
      label: "Config",
      children: <ConfigTab />,
    },
  ];

  return (
    <Modal
      title=""
      open={open}
      footer={false}
      closeIcon={false}
      onCancel={handleCancel}
      className="no-padding"
    >
      <FormProvider {...method}>
        <form
          className="rounded-[10px] lg:w-[600px] 2xl:w-[700px] lg:h-[75vh] 2xl:h-[70vh] overflow-hidden"
          onSubmit={handleSubmit(handleCreateRoom)}
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important",
          }}
        >
          <div className="flex items-center justify-between bg-[#ABABAB] p-[20px]">
            <h5 className="text-[20px]">게임방 생성</h5>
          </div>
          <div className="p-[10px_20px] lg:h-[82%] 2xl:h-[88%]">
            <Tabs defaultActiveKey="1" items={itemsTab} className="detailTab" />
            <div className="m-[20px_0px_10px_0px] flex justify-end">
              <button
                type="submit"
                className="bg-[#777777] text-[#fff] rounded-[30px] outline-none border-none lg:w-[130px] 2xl:w-[150px] lg:p-[10px_7px] 2xl:p-[15px_10px]"
              >
                생성
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default FormCreateRoom;
