import React from "react";
import { popupIdSignUp } from "../constant";
import { CustomPopup } from "share/components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Input } from "antd";
import { useVerify } from "../hook/useVerify";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import toast from "react-hot-toast";
import { useGetOTP } from "../hook/useGetOTP";
import { closePopup } from "redux/action/custom-popup/customPopupAction";

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate, isLoading } = useVerify();

  const {mutate: resendOTP, isLoading: isLoadingResendOTP} = useGetOTP();

  const method = useForm({
    mode: "onChange",
    defaultValues: {
      otpCode: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        otpCode: yup.string().required().min(6),
      })
    ),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = method;

  const handleCancel = () => {
    dispatch(closePopup({id: popupIdSignUp.confirmEmail}))
  };

  const handleOk = async (data) => {
    // dispatch(closePopup({ id: popupIdSignUp.confirmEmail }));
    // navigate("/login");
    const dataForm = {
      otpIdUser: parseInt(localStorage.getItem('uidRegister')),
      otpCode: data.otpCode,
    };
    await mutate({
      ...dataForm,
      onSuccess: (res) => {
        toast.dismiss();
        toast.success("Verify succesfull");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error("OTP is incorrect or expire");
      },
    });
  };

  const handleResend = async () => {
    const dataForm = {
      otpIdUser: parseInt(localStorage.getItem('uidRegister')),
      otpValidTime: new Date(),
    };
    await resendOTP({
      ...dataForm,
      onSuccess: (res) => {
        toast.dismiss();
        toast.success("Resend successfull. Please check in your email");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error("Resend fail");
      },
    })
  }

  useLoadingEffect(isLoading || isLoadingResendOTP);

  return (
    <CustomPopup
      id={popupIdSignUp.confirmEmail}
      style={{ padding: 0 }}
      closeIcon={false}
      className="no-padding"
      onCancel={handleCancel}
    >
      <FormProvider {...method}>
        <form
          className="rounded-[20px] border-[2px] border-solid border-[#FFDF00] p-[10px_20px] lg:p-[20px_40px] flex flex-col items-center w-[90vw] lg:w-[60vw] 2xl:w-[40vw] h-fit box-border"
          style={{ backgroundColor: "rgba(119,119,119,0.7)" }}
          onSubmit={handleSubmit(handleOk)}
        >
          <img
            src="/images/mail.png"
            className="w-[80px] lg:w-[140px] 2xl:w-[200px] h-fit"
          />
          <div className="text-center text-[12px] lg:text-[15px] 2xl:text-[18px] text-white grid grid-cols-1 gap-[10px] lg:gap-[20px]">
            <div>---</div>
            <p>Thank you for registering with Livegame!</p>
            <p>
              To complete the activation of your account, please check your
              email inbox for a message from us. Inside the email, you will
              receive a OTP code. Input code and click button confirm to
              activation your account.
            </p>
            <p>
              If you do not see the email in your inbox, please check your spam
              or junk folder.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center mt-[10px] lg:mt-[20px]">
            <div>
              <h5 className="text-[14px] lg:text-[16px] 2xl:text-[20px] text-[#13563B] mb-[10px]">
                OTP Verification
              </h5>
            </div>
            <Controller
              name="otpCode"
              control={control}
              render={({ field }) => {
                return <Input.OTP length={6} {...field} />;
              }}
            />
            <button
              className="mt-[15px] lg:mt-[20px] p-[10px] min-w-[100px] rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] flex items-center justify-center ml-[10px]"
              type="submit"
            >
              OK
            </button>
            <div className="mt-[10px] text-center text-[11px] lg:text-[13px] 2xl:text-[16px]">
              <span>You don't receive the code? </span>
              <span className="font-bold underline cursor-pointer" onClick={handleResend}>resend</span>
            </div>
          </div>
        </form>
      </FormProvider>
    </CustomPopup>
  );
};

export default ConfirmEmail;
