import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChatOpen } from "share/function";
import FormChat from "./components/FormChat";
import Chat from "./components/Chat";
import ShowChat from "./components/ShowChat";
import { updateChat } from "redux/action/roomAction";

const ChatArea = ({socket}) => {
  const dispatch = useDispatch();

  // const fakeChat = [
  //   {
  //     content: "Hello My Friend!",
  //     Player: {
  //       userName: "test",
  //       nickName: "Bụng bự",
  //     },
  //   },
  //   {
  //     content: "Hello Cái",
  //     Player: {
  //       userName: "test2",
  //       nickName: "Tóc Xoăn",
  //     },
  //   },
  //   {
  //     content: "Bạn ăn cơm chưa? Bạn ăn cơm với món gì vậy",
  //     Player: {
  //       userName: "test",
  //       nickName: "Bụng bự",
  //     },
  //   },
  //   {
  //     content: "...",
  //     Player: {
  //       userName: "test2",
  //       nickName: "Tóc Xoăn",
  //     },
  //   },
  // ];

  return (
    <div
      className="absolute left-[100%] w-[300px] p-[10px] bg-[#fff] h-full flex flex-col items-center justify-between box-border"
      id="chatRoom"
    >
      Chat Area
      <Button
        className="absolute left-[10px] top-[10px] p-[10px_5px]"
        style={{ transform: "translateX(-5px)" }}
        onClick={() => {
          dispatch(updateChat(false));
          handleChatOpen(false);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </Button>
      <ShowChat/>
      <FormChat socket={socket}/>
    </div>
  );
};

export default ChatArea;
