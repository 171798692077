import { Popover, Select } from "antd";
import PopupCommonRooms from "main/user/room/components/popup/CommonRooms";
import GameGuidePopup from "main/user/room/components/popup/GameGuidePopup";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openPopup } from "redux/action/custom-popup/customPopupAction";
import { POPUP_ID } from "share/constantShared";
import ProfilePopover from "./components/ProfilePopover";
import { SocketUserProvider } from "fuse/layout-user/layout-master";

const ToolbarHome = ({handleSearch}) => {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.home);

  const {socket} = useContext(SocketUserProvider);

  const commonPopup = (type = "common") => {
    switch (type) {
      case "common": {
        dispatch(openPopup({ id: POPUP_ID.COMMON_ROOMS }));
        break;
      }
      case "guide": {
        dispatch(openPopup({ id: POPUP_ID.COMMON_GUIDE }));
        break;
      }
    }
  };
  return (
    <>
      <div className="flex items-center justify-evenly bg-[#6F4E37] text-[#fff] rounded-[20px]">
        <div
          className="text-[11px] lg:text-[15px] 2xl:text-[18px] cursor-pointer p-[5px] lg:p-[5px_10px] 2xl:p-[10px_15px]"
          onClick={() => commonPopup("common")}
        >
          Common rooms
        </div>
        <div
          className="text-[11px] lg:text-[15px] 2xl:text-[18px] cursor-pointer p-[5px] lg:p-[5px_10px] 2xl:p-[10px_15px]"
          onClick={() => commonPopup("guide")}
        >
          Game guide
        </div>
        <Select
          className="w-[50px] text-[11px] lg:w-[130px] m-[5px] lg:m-[5px_10px] 2xl:m-[10px_15px]"
          defaultValue={"en"}
          options={[
            { label: "English", value: "en" },
            { label: "Korea", value: "ko" },
          ]}
        />
        <Popover
          content={<ProfilePopover />}
          trigger="hover"
          placement="bottom"
        >
          <div className="text-[11px] lg:text-[15px] 2xl:text-[18px] cursor-pointer flex items-center p-[5px] lg:p-[5px_10px] 2xl:p-[10px_15px]">
            <div className="w-[10px] h-[10px] lg:w-[15px] lg:h-[15px] rounded-[50%] bg-[#28f354dc] mr-[10px]"></div>
            <span className="mr-[10px] font-bold">{userInfo?.nickName}</span>
          </div>
        </Popover>
        {/* <button onClick={()=> {
          socket.send(JSON.stringify({
          FunctionCode: 'create_room'}))
        }}>Refresh</button> */}
      </div>
      <PopupCommonRooms id={POPUP_ID.COMMON_ROOMS} handleSearch={handleSearch}/>
      <GameGuidePopup id={POPUP_ID.COMMON_GUIDE} />
    </>
  );
};

export default ToolbarHome;
