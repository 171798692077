import { useContext, useEffect } from "react";
import { LoadingContext } from "../loading/LoadingProvider";

const useLoadingEffect = (loading) => {
  const { setIsLoading } = useContext(LoadingContext);
  
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
};

export default useLoadingEffect;
