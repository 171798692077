import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCreateRoom } from "../roomMgt/hook/useCreateRoom";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import toast from "react-hot-toast";
import { socket } from "context/socket";
import useGetRoom from "../roomMgt/hook/useGetRoom";
import { useFilters } from "share/function";
import { useNavigate } from "react-router-dom";

const CreateRoom = () => {
  const fakeData = [
    {
      value: 1,
      label: "판돈",
    },
  ];


  const second = [
    {
      value: 1,
      label: 15,
    },
    {
      value: 2,
      label: 30,
    },
    {
      value: 3,
      label: 45,
    }
  ];


  const navigate = useNavigate()
  const [isCreate, setIsCreate] = useState(false);
  const { count, setCount, filters, onFilter } = useFilters({});
  const {mutate, isLoading} = useCreateRoom();
  const {
    data,
    isLoading: loadingRoom,
    isFetching,
    fetch
  } = useGetRoom(filters);

  const { control, getValues, reset, watch, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: {
      roomPrice: 1,
      roomName: "",
      roomStatus: 1,
      roomTime: 1,
    },
  });

  useEffect(()=> {
    if(data?.data) {
      setValue("roomName", `Room ${data?.data?.length + 1}`)
    }
  }, [data])

  const handleCreateRoom = async (data) => {
    const dataSubmit = {...data, roomTime: parseInt(data.roomTime)};
    await mutate({
      ...dataSubmit,
      onSuccess: async(msg) => {
        // dispatch(loginSuccess(msg.data))
        toast.success("Create success");
        socket.emit("create_room", msg.data)
        await fetch();
        navigate("/admin/roomMgt")
      },
      onError: (msg) => {
        toast.error(msg.message)
      },
    });
  }

  useLoadingEffect(isLoading);

  return (
    <div
      className="relative w-full h-full lg:w-[1100px] 2xl:w-[1300px] bg-contain bg-center bg-no-repeat flex items-center justify-center"
      style={{ backgroundImage: "url('/images/Dealer.png')" }}
    >
      {/* <img
        src="/images/Dealer.png"
        className="absolute top-[50%] left-[50%]"
        style={{ transform: "translate(-50%, -50%)" }}
      /> */}
      <form className="rounded-[10px] w-[500px]" onSubmit={handleSubmit(handleCreateRoom)} style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important'}}>
        <div className="flex items-center justify-between bg-[#ABABAB] p-[20px]">
          <h5 className="text-[20px]">게임방 생성</h5>
          <div></div>
        </div>
        <div className="grid grid-cols-1 gap-[20px] p-[20px] bg-[white]">
          <div>
            <Controller
              control={control}
              name="roomPrice"
              render={({ field }) => {
                return (
                  <Select
                    style={{ width: "100%" }}
                    options={fakeData}
                    {...field}
                  />
                );
              }}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="roomName"
              render={({ field }) => {
                return <Input {...field} className="lg:p-[10px] 2xl:p-[15px]" disabled/>;
              }}
            />
          </div>
          <div className="flex justify-between">
            <div>
              <Controller
                control={control}
                name="roomTime"
                render={({ field }) => {
                  return (
                    <Select
                      style={{ width: "150px" }}
                      options={second}
                      defaultValue={1}
                      {...field}
                    />
                  );
                }}
              />
            
            </div>
            <button type="submit" className="bg-[#777777] text-[#fff] rounded-[30px] outline-none border-none w-[150px] p-[15px_10px]">
                생성
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateRoom;
