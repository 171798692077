export const updateCameraStream = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_CAMERA_STREAM",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const updateVolume = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_VOLUME",
      payload: data,
    });
  } catch (err) {
    return err;
  }
};
